import axios from "axios";
import { errorNotification } from "../../Components/CustComp/PPHAlert";
import { BASEURL } from "../config";

// ================= Auth & User Specific URLs ===============================
export const LOGIN_URL = BASEURL.concat("/user/login");
export const REGISTER_URL = BASEURL.concat("/user/register")
export const GET_MY_DETAILS = BASEURL.concat("/user/get-my-info")
export const UPDATE_PASSWORD = BASEURL.concat("/user/update-password")
export const SEND_VERIFY_EMAIL_OTP = BASEURL.concat("/user/send-verify-email-otp")
export const VERIFY_USER_EMAIL = BASEURL.concat("/user/verify-user-email")
export const SEND_EMAIL_CHANGE_OTP = BASEURL.concat("/user/send-email-change-otp")
export const UPDATE_EMAIL = BASEURL.concat("/user/update-email")
export const SEND_FORGOT_PASSWORD_OTP = BASEURL.concat("/user/send-forgot-password-otp")
export const FORGOT_PASSWORD_URL = BASEURL.concat("/user/forgot-password")
export const UPDATE_PROFILE_PICTURE_URL = BASEURL.concat("/user/update-user-profile-picture")
export const SEARCH_USER_URL = BASEURL.concat("/user/search-user")

// ================= Upgrade User Account URLs ================================
export const UPGRADE_USER_URL = BASEURL.concat("/user/user-upgrade")
export const GET_LEVEL_PRICING_URL = BASEURL.concat("/user/get-user-upgrade-pricing")

// ============================== Coin URLs ====================================
export const COIN_TRANSFER_URL = BASEURL.concat("/user/coin-transfer/")
export const COIN_HISTORY_URL = BASEURL.concat("/user/get-coins-history/")

// ================= Club Specific URLs =========================================
export const GET_CLUB_IDENTITIES_URL = BASEURL.concat("/user/get-club-identities/")
export const CREATE_CLUB_URL = BASEURL.concat("/user/create-club")
export const GET_CLUBS_URL = BASEURL.concat("/user/get-clubs")
export const UPDATE_CLUB_URL = BASEURL.concat("/user/update-club/")
export const REMOVE_CLUB_URL = BASEURL.concat("/user/remove-club/")


// ================= Club Config URLs ===========================================
export const UPDATE_LEADERBOARD_STATUS_URL = BASEURL.concat("/user/update-leaderboard-status/")
export const CHANGE_SPORTSBOOK_URL = BASEURL.concat("/user/change-sportsbook")
export const GET_CLUB_BET_LIMITS_URL = BASEURL.concat("/user/get-club-bet-limits/")
export const UPDATE_CLUB_BET_LIMIT_URL = BASEURL.concat("/user/update-club-bet-limits")
export const GET_CLUB_ODD_LIMITS_URL = BASEURL.concat("/user/get-club-odd-limits/")
export const UPDATE_ODD_LIMIT_URL = BASEURL.concat("/user/update-odd-limits")
export const UPDATE_BET_DELAY_URL = BASEURL.concat("/user/update-bet-delay/")

// =================  Upgrade Club URLs ========================================
export const UPGRADE_CLUB_URL = BASEURL.concat("/user/club-upgrade")
export const GET_CLUB_LEVEL_PRICING_URL = BASEURL.concat("/user/get-club-upgrade-pricing")

// ================= Membership URLs ===========================================
export const GET_CLUB_APPLICANTS = BASEURL.concat("/user/get-club-applicants/")
export const CREATE_CLUB_REQUEST_URL = BASEURL.concat("/user/send-request-to-club/")
export const GET_ALL_CLUB_MEMBER = BASEURL.concat("/user/get-club-members/");
export const HANDLE_CLUB_REQUEST_URL = BASEURL.concat("/user/handle-club-request/")
export const SWITCH_MEMBERS = BASEURL.concat("/user/switch-members")
export const REMOVE_MEMBERS = BASEURL.concat("/user/remove-members")
export const LEAVE_CLUB_URL = BASEURL.concat("/user/leave-club/")

//  ================= Membership Config URLs ===================================
export const GET_PLAYER_BET_LIMIT_URL = BASEURL.concat("/user/get-player-bet-limits")
export const UPDATE_PLAYER_BET_LIMIT_URL = BASEURL.concat("/user/update-player-bet-limits")
export const UPDATE_CHIP_AUTO_RESET_STATUS = BASEURL.concat("/user/update-chip-auto-reset-status")
export const MEMBER_STOP_LIMITS = BASEURL.concat("/user/get-player-stop-limit")
export const UPDATE_MEMBER_STOP_LIMIT = BASEURL.concat("/user/update-player-stop-limit")
export const GET_NOTES = BASEURL.concat("/user/get-notes")
export const CREATE_NOTES = BASEURL.concat("/user/update-notes")
export const MEMBER_STATUS = BASEURL.concat("/user/update-player-status")

// ================= Agent Specific URLs =======================================
export const SUB_AGENT_URL = BASEURL.concat("/user/agents")
export const AGENT_PLAYERS = BASEURL.concat("/user/agent-players/")

// ================= Player Specific URLs ======================================
export const UPDATE_PLAYER_KICKBACKS = BASEURL.concat("/user/update-player-kickbacks/")

// ================= Chips Purchase URLs =======================================
export const GET_CHIPS_PRICING_URL = BASEURL.concat("/user/get-chips-pricing")
export const BUY_CHIPS_URL = BASEURL.concat("/user/buy-chips")

// ================= Chips URLs ================================================
export const CREATE_CHIPS_REQUEST_URL = BASEURL.concat("/user/create-chips-request")
export const GET_CHIPS_REQUEST_URL = BASEURL.concat("/user/get-chips-request")
export const GET_CHIPS_RESET_CYCLE = BASEURL.concat("/user/get-chips-reset-cycle/")
export const UPDATE_CHIPS_RESET_CYCLE = BASEURL.concat("/user/update-chips-reset-cycle")
export const RESET_CHIPS_URL = BASEURL.concat("/user/reset-all-chips")
export const CHIPS_TRANSACTION_URL = BASEURL.concat("/user/chips-transactions")
export const HANDLE_CHIPS_REQUEST_URL = BASEURL.concat("/user/handle-chip-request")
export const GET_WALLET_HISTORY = BASEURL.concat("/user/get-wallet-history")
export const GET_CHILD_WALLETS = BASEURL.concat("/user/get-child-wallets/")

// ================= Club Message URLs ================================
export const GET_CLUB_CHANNEL = BASEURL.concat("/user/get-club-channels/");
export const GET_CLUB_CHANNEL_MEMBER = BASEURL.concat("/user/get-channel-members/");
export const ADD_CLUB_CHANNEL = BASEURL.concat("/user/create-club-channel/");
export const GET_ALL_MESSAGE = BASEURL.concat("/user/get-channel-messages/");
export const ADD_REMOVE_CLUB_MEMBER=BASEURL.concat("/user/addremove-channel-members/");
export const UPDATE_CHANNEL_NAME=BASEURL.concat("/user/update-club-channel/")
export const DELETE_CLUB_CHANNEL=BASEURL.concat("/user/delete-club-channel/")
export const MARK_CHANNEL_MESSAGES_SEEN=BASEURL.concat("/user/mark-channel-messages-seen/")
export const GET_SEENBY_MEMBERS=BASEURL.concat("/user/get-seenby-members/")
export const GET_MESSAGE_REACTION=BASEURL.concat("/user/get-message-reacts/")
export const SEARCH_CLUB_MEMBER=BASEURL.concat("/user/search-club-members/")

// ================= Notification URLs ==========================================
export const NOTIFICATIONS_URL = BASEURL.concat("/user/get-notifications/")
export const MARKED_AS_READ_NOTIFICATIONS_URL = BASEURL.concat("/user/marked-all-as-read-notifications/")
export const DELETE_NOTIFICATIONS_URL = BASEURL.concat("/user/delete-notifications/")
export const GET_NOTIFICATION_STATUS = BASEURL.concat("/user/get-notification-status")
export const UPDATE_NOTIFICATION_STATUS = BASEURL.concat("/user/update-notification-status")
export const UPDATE_SUBSCRIPTION = BASEURL.concat("/user/update-subscription")
export const UPLOAD_MEDIA_CHAT = BASEURL.concat("/user/upload-chat-media/")

// ================= Token URLs ==================================================
export const MY_TOKEN_URL = BASEURL.concat("/user/get-my-tokens/")
export const TRANSFER_TOKEN_URL = BASEURL.concat("/user/transfer-token/")
export const ASSIGN_TOKEN_TO_CLUB_URL = BASEURL.concat("/user/assign-token-to-club/")
export const UNASSIGN_TOKEN_URL = BASEURL.concat("/user/unassign-token/")

// ================= Coin & Token Payment URLs ===================================
export const GET_FOR_SALE_TOKENS = BASEURL.concat("/user/get-for-sale-tokens")
export const GET_PRICING_URL = BASEURL.concat("/user/get-pricing")
export const CREATE_INVOICE_URL = BASEURL.concat("/user/create-invoice")
export const PAYMENT_STATUS_URL = BASEURL.concat("/user/get-payment-status/")
export const GET_ORDER_HISTORY = BASEURL.concat("/user/get-order-history")

// ================= Coupon URLs =================================================
export const CHECK_COUPON_URL = BASEURL.concat("/user/check-coupon")

// ================ Main Affiliate Report URLs ================
export const AFFILIATE_SUMMARY = BASEURL.concat("/user/get-main-affi-summary")
export const AFFILIATE_TIER_DETAILS = BASEURL.concat("/user/get-main-affi-tier-details")
export const AFFILIATE_MEMBERS_DETAILS = BASEURL.concat("/user/get-main-affi-member-details")

// ============================== Contact Mail URLs ==============================
export const CONTACT_URL = BASEURL.concat("/user/contact-me")

// ================= News URLs ===================================================
export const GET_NEWS = BASEURL.concat("/user/get-news")


// ================= Events URLs =================================
export const GET_SPORTS = BASEURL.concat("/sportsbook/get-sports/")
export const GET_EVENTS = BASEURL.concat("/sportsbook/get-events/")
export const GET_TEASER_SPORTS=BASEURL.concat("/sportsbook/get-teaser-sports")
export const GET_PROPS_MARKET=BASEURL.concat("/sportsbook/get-props-markets")



// ================= Place Bet URLs ===============================
export const STRAIGHT_PLACE_BET = BASEURL.concat("/sportsbook/straight-place-bet")
export const PARLAY_PLACE_BET = BASEURL.concat("/sportsbook/parlay-place-bet")
export const TEASER_PLACE_BET=BASEURL.concat("/sportsbook/teaser-place-bet/")

// ================= Bet Action URLs ===============================
export const DELETE_PLACE_BET = BASEURL.concat("/sportsbook/delete-placed-bet/")

// ================= Player Specific Reports URLs ==================
export const PLAYER_BET_HISTORY = BASEURL.concat("/sportsbook/player-bet-history/")
export const PLAYER_GET_SUMMARY = BASEURL.concat("/sportsbook/player-summary/")
export const PLAYER_BET_HISTORY_REPORT = BASEURL.concat("/sportsbook/player-bet-history-report/");

// ================= Club Specific Reports URLs =====================
export const GET_CLUB_LEADERBOARD = BASEURL.concat("/sportsbook/club-leaderboard/")
export const CLUB_BET_HISTORY = BASEURL.concat("/sportsbook/club-bet-history/")
export const CLUB_DETAILED_BET_HISTORY = BASEURL.concat("/sportsbook/club-bet-detailed-history/")
export const CLUB_GET_SUMMARY = BASEURL.concat("/sportsbook/club-summary/")
export const GET_ALL_PLAYER_SUMMARY = BASEURL.concat("/sportsbook/all-player-summary/")

// ================= FreePlay Challenege Reports URLs ==============
export const GET_WINNERS_REPORT = BASEURL.concat("/sportsbook/challenge-public-info")
export const CHALLENGE_PLAYER_SUMMARY = BASEURL.concat("/sportsbook/challenge-player-summary")
export const GET_WINNERS_URL = BASEURL.concat("/sportsbook/get-winner-list")

// ================ Challenge Affiliate Report URLs ================
export const REWARD_SUMMARY = BASEURL.concat("/sportsbook/get-chall-affi-summary")
export const REWARD_TIER_DETAILS = BASEURL.concat("/sportsbook/get-chall-affi-tier-details")
export const REWARD_MEMBERS_DETAILS = BASEURL.concat("/sportsbook/get-chall-affi-member-details")


export const POLYMARKETS_LISTS = BASEURL.concat("/sportsbook/gamma/events")
export const POLYMARKETS_SLUG_LISTS = ("https://polymarket.com/api/tags/filteredBySlug")

export const POLYMARKETS_FILTERS_LISTS = ("https://polymarket.com/api/tags/filtered?tag=100221&status=active")
export const STRAIGHT_POLY_PLACE_BET = BASEURL.concat("/valuemarket/place-straight-bet")



// POST Management
export async function postValuebet(url, data, recaptcha=false) {
  const access = localStorage.getItem("authToken");
  let headers = { headers: {}};
  if (recaptcha) {
    const recptcha_token = await window.grecaptcha.execute("6Lelwm8qAAAAABLVsvQy9bmid-8Y1HvoAsT5I8SA", { action: "submit" });
    Object.assign(headers.headers, {'X-RECAPTCHA-TOKEN':recptcha_token})
  }
  if (access) Object.assign(headers.headers, { Authorization: 'Bearer '.concat(access) })
  let result = null;
  try {
    let res = await axios.post(url, data, headers)
    result = res.data
  } catch (error) {
    let msg = error?.response?.data?.message;
    if (msg) {
      errorNotification(msg);
    } else if (error?.response?.status === 401) { 
      errorNotification('Username and Password combination doesn’t match any records.')
    } else {
      errorNotification("Internal Server Error!");
    }
  }
  return result;
}

// PUT Management
export async function putValuebet(url, data) {
  const access = localStorage.getItem("authToken");
  let result = null;
  try {
    let res = await axios.put(url, data, { headers: { Authorization: 'Bearer '.concat(access) } })
    result = res.data
  } catch (error) {
    let msg = error?.response?.data?.message;
    if (msg) {
      errorNotification(msg);
    } else {
      errorNotification("Internal Server Error!");
    }
  }
  return result;
}


// DELETE Management
export async function deleteValuebet(url) {
  const access = localStorage.getItem("authToken");
  let result = null;
  try {
    let res = await axios.delete(url, { headers: { Authorization: 'Bearer '.concat(access) } })
    result = res.data;
  } catch (error) {
    let msg = error?.response?.data?.message;
    if (msg) {
      errorNotification(msg);
    } else {
      errorNotification("Internal Server Error!");
    }
  }
  return result;
}


// GET Management
export async function getValuebet(url) {
  const access = localStorage.getItem("authToken");
  let headers = {}
  if (access){
    headers = { Authorization: 'Bearer '.concat(access) };
  }
  let result = null;
  try {
    let res = await axios.get(url, { headers })
    result = res.data
  } catch (error) {
    let msg = error?.response?.data?.message;
    if (msg) {
      console.log(msg);
      errorNotification(msg);
    } else {
      console.log("Internal Server Error!");
      if (url.includes("/user/get-clubs")){
        errorNotification("Session Expire!");
        localStorage.removeItem("authToken");
        setTimeout(() => {
          window.location = '/';
        }, 3000);
      }
    }
  }
  return result;
}

