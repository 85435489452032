import React, { useContext, useEffect } from 'react'
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';
import { postValuebet, PARLAY_PLACE_BET } from '../../../../API-Handle/services/auth_curd';
import { errorNotification, successNotification } from '../../../../Components/CustComp/PPHAlert';
import { useLocation } from 'react-router-dom';
import { OFFLINE_ODD } from '../../../../constant';
import {
  Card,
  CardBody,
} from "reactstrap";
import { checkBetCombination, getMultiDecimalOdd } from '../../../../Utils/sportsbook'
import DelayedTimer from '../../../../Components/CustComp/DelayedTimer';
import Checkbox from '../../../Sportbook/Sports/Bets/BetComman/Checkbox';
import BetConfirmation from '../../../Sportbook/Sports/Bets/BetComman/BetConfirmation';

function BetBottom() {
  const prev_bet_count = React.useRef(0);
  const timeoutRef = React.useRef(null);
  const [isSelect, setisSelect] = React.useState(false)
  const [isConfirm, setIsConfirm] = React.useState(true)
  const [isValidCombination, setIsValidCombination] = React.useState(true)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [resData, setResData] = React.useState(false)
  const location = useLocation();
  const [warnMessage, setWarnMessage] = React.useState(false)
  const { betDetails, rootLoader, setBetDetails, setRootLoader, fetchSingleClub, activeClub, getMultiBetMinLimit, getMultiBetMaxLimit, getMultiBetMaxPayoutLimit } = useContext(ValuebetContext);

  const setBetAmountLimits = () => {
    let message = false;
    let min_limit = getMultiBetMinLimit(betDetails.bets);
    let max_limit = getMultiBetMaxLimit(betDetails.bets, betDetails?.bet_info?.bet_type);
    let max_payout_limit = getMultiBetMaxPayoutLimit(betDetails.bets);
    let decimal_odd = getMultiDecimalOdd(betDetails.bets);

    if (decimal_odd !== OFFLINE_ODD) {
      if (betDetails?.bet_info?.risking) {
        if (decimal_odd >= 2) {
          if (max_limit < betDetails?.bet_info?.risking || betDetails?.bet_info?.risking < min_limit) {
            message = `Risking value must be greater-than or equal to ${min_limit} and less-than or equal to ${max_limit}.`;
          }
        } else {
          let risk_max_limit = max_limit / (decimal_odd - 1);
          risk_max_limit = Number(risk_max_limit.toFixed(2));
          if (risk_max_limit < betDetails?.bet_info?.risking || betDetails?.bet_info?.risking < min_limit) {
            message = `Risking value must be greater-than or equal to ${min_limit} and less-than or equal to ${risk_max_limit}.`;
          }
        }
        if (!message && max_payout_limit < betDetails?.bet_info?.to_win) {
          message = `Parlay Max Pay Limit is ${max_payout_limit}.`;
        }
        if (!message && betDetails?.bet_info?.risking > activeClub?.user_chips?.player_chips) {
          message = `You have insufficient chips to place bets!`;
        }
      }
    }
    setWarnMessage(message)
  }

  const setOddChangeConfirmaions = () => {
    let is_confirm = false;
    if (betDetails?.bet_info?.risking !== betDetails?.bet_info?.init_risk || betDetails?.bet_info?.to_win !== betDetails?.bet_info?.init_win) {
      is_confirm = true;
    }
    if (!is_confirm) {
      for (let bet of betDetails.bets) {
        if (bet.init_sub_title !== bet.sub_title) {
          is_confirm = true;
          break;
        }
      }
    }
    setIsConfirm(is_confirm)
  }

  useEffect(() => {
    if (isConfirm && !isSelect && timeoutRef) {
      clearTimeout(timeoutRef.current);
      setRootLoader(false);
    }
    // eslint-disable-next-line
  }, [isConfirm])

  useEffect(() => {
    setBetAmountLimits();
    setOddChangeConfirmaions();
    setIsValidCombination(checkBetCombination(betDetails.bets))
    // eslint-disable-next-line
  }, [activeClub, betDetails.bet_info])

  useEffect(() => {
    if (prev_bet_count.current !== betDetails.bets.length) {
      let bet_info = {
        bet_type: "Parlay",
        risking: null,
        to_win: null,
        init_risk: null,
        init_win: null,
      }
      setBetDetails({ ...betDetails, bet_info })
      setIsValidCombination(checkBetCombination(betDetails.bets))
    }
    return (() => {
      prev_bet_count.current = betDetails.bets.length;
    })
    // eslint-disable-next-line
  }, [betDetails.bets])


  // Remove All Bets
  const removeAllBets = async () => {
    setBetDetails({ ...betDetails, bets: [] })
  }

  const acceptChanges = async () => {
    let bet_details = JSON.parse(JSON.stringify(betDetails));
    for (let bet of bet_details.bets) {
      bet.init_sub_title = bet.sub_title;
    }
    if (bet_details?.bet_info?.risking !== undefined || bet_details?.bet_info?.risking !== null) bet_details.bet_info.init_risk = bet_details.bet_info.risking;
    if (bet_details?.bet_info?.to_win !== undefined || bet_details?.bet_info?.to_win !== null) bet_details.bet_info.init_win = bet_details.bet_info.to_win;
    setBetDetails(bet_details)
    placeBet();
  }

  const placeBet = async () => {
    if ((betDetails.bets.length < 7) && isValidCombination && !warnMessage) {
      setRootLoader(true)
      if (!betDetails?.bet_info?.risking) {
        errorNotification("Please enter valid risking value!");
        setRootLoader(false)
        return
      }
      timeoutRef.current = setTimeout(async () => {
        let payloads = {
          auto_accept: isSelect,
          club_id: location?.state?.club?.id,
          ...betDetails.bet_info,
          odds: []
        }

        for (let bet of betDetails.bets) {
          if (bet.odd_money === OFFLINE_ODD) {
            errorNotification("Please remove ticket which is no longer available.");
            setRootLoader(false)
            return
          }
          const odd_data = {
            odd_id: bet.id,
            odd_money: bet.odd_money,
            odd_points: bet.odd_points
          }
          if (bet?.proposition) {
            const proposition_data = {
              proposition: bet.proposition,
              event_id: bet.event_id,
              sportsbook_id: bet.sportsbook_id,
              market_id: bet.market_id,
              participant_id: bet.participant_id,
              participant_type: bet.participant_type,
              period_id: bet.period_id
            }
            Object.assign(odd_data, proposition_data);
          }
          payloads.odds.push(odd_data);
        }
        let res = await postValuebet(PARLAY_PLACE_BET, payloads)
        if (res?.message) {
          successNotification(res.message)
          setResData(res)
          setIsSuccess(true)
          fetchSingleClub(location?.state?.club?.id)
          window.gtag('event', 'conversion', { 'send_to': 'AW-16724820378/U3L_CLChgN0ZEJqDgqc-' });
        }
        setRootLoader(false)
      }, activeClub?.bet_delay_toggle ? activeClub?.bet_delay_seconds * 1000 : 0);
    }
  }

  const toggleOnBetSuccess = () => {
    removeAllBets();
    setIsSuccess(false);
  }

  // Calculate Parlay Bet
  const calculateParlayBet = async (risking, to_win, is_auto_fix) => {
    let decimal_odd = getMultiDecimalOdd(betDetails.bets);
    let max_payout = getMultiBetMaxPayoutLimit(betDetails.bets);
    let risk_val = ''
    let win_val = ''
    if (decimal_odd !== OFFLINE_ODD) {
      if (risking !== null && risking !== '') {
        risk_val = Math.abs(risking);
        win_val = Number(((decimal_odd * risk_val) - risk_val).toFixed(2))
      }

      if (to_win !== null && to_win !== '') {
        win_val = Math.abs(to_win);
        risk_val = Number((win_val / (decimal_odd - 1)).toFixed(2))
      }
    }

    if (is_auto_fix && (max_payout < win_val)) {
      calculateParlayBet(null, max_payout, true);
    } else if (is_auto_fix && (activeClub?.user_chips?.player_chips < risk_val)) {
      calculateParlayBet(activeClub?.user_chips?.player_chips, null, true);
    } else {
      let bet_info = {
        bet_type: "Parlay",
        risking: risk_val,
        to_win: win_val,
        init_risk: risk_val,
        init_win: win_val,
      }
      setBetDetails({ ...betDetails, bet_info })
    }
  }

  const hitMaxRisk = () => {
    let max_limit = getMultiBetMaxLimit(betDetails.bets, betDetails?.bet_info?.bet_type);
    let decimal_odd = getMultiDecimalOdd(betDetails.bets);
    if (decimal_odd !== OFFLINE_ODD) {
      if (decimal_odd >= 2) {
        calculateParlayBet(max_limit, null, true);
      } else {
        calculateParlayBet(null, max_limit, true);
      }
    }
  }

  return (
    <>
      <BetConfirmation isActive={isSuccess} toggleHandler={toggleOnBetSuccess} data={resData} />
      <div className='bet-bottom'>
        {betDetails.bets.length > 1 ?
          <div>
            {isValidCombination ?
              <Card className="bet-card">
                <CardBody className="px-2 py-3">
                  <div className='d-flex justify-content-center mb-2 px-10per'>
                    <div className='me-2'>
                      <input
                        type="number"
                        value={betDetails?.bet_info?.risking || ''}
                        min="0"
                        className={`form-control form-control-sm bg-vb-dark border border-danger ${(betDetails?.bet_info?.init_risk && betDetails?.bet_info?.init_risk !== betDetails?.bet_info?.risking) ? 'text-danger' : 'text-white'} card-input`}
                        placeholder="Risking"
                        onChange={(e) => calculateParlayBet(e.target.value, null, false)}
                      />
                      {(betDetails?.bet_info?.init_risk && betDetails?.bet_info?.init_risk !== betDetails?.bet_info?.risking) && <div className="font-size-12px ps-2 ms-1 text-start"><del>{betDetails?.bet_info?.init_risk}</del></div>}
                    </div>
                    <div className='ms-2'>
                      <input
                        type="number"
                        value={betDetails?.bet_info?.to_win || ''}
                        min="0"
                        className={`form-control form-control-sm bg-vb-dark border border-danger ${(betDetails?.bet_info?.init_win && betDetails?.bet_info?.init_win !== betDetails?.bet_info?.to_win) ? 'text-danger' : 'text-white'} card-input`}
                        placeholder="To win"
                        onChange={(e) => calculateParlayBet(null, e.target.value, false)}
                      />
                      {(betDetails?.bet_info?.init_win && betDetails?.bet_info?.init_win !== betDetails?.bet_info?.to_win) && <div className="font-size-12px ps-2 ms-1 text-start"><del>{betDetails?.bet_info?.init_win}</del></div>}
                    </div>
                  </div>

                  {warnMessage ?
                    <div className='text-center text-danger'>
                      <small>{warnMessage}</small>
                    </div> : null}
                  <div className='text-center'>
                    <small>Enter amount in box or select below</small>
                  </div>

                  <div className='d-flex justify-content-center'>
                    <button className='btn-outline-box mt-3' onClick={hitMaxRisk}>
                      MAX
                    </button>
                  </div>
                </CardBody>
              </Card>
              : <div className='text-center text-danger mb-2'>
                Some selections cannot be combined in a Parlay.
              </div>}
            {(isConfirm && !isSelect) && <div className='text-warning mb-2'>Odds have changed. Accept new Odds?</div>}
            <Checkbox label={'Auto Accept Changes'} select={isSelect} onChange={() => setisSelect(!isSelect)} />
            {isConfirm && !isSelect ?
              <div className='mb-2'>
                <button className='btn btn-secondary btn-sm w-40' onClick={removeAllBets}>
                  Cancel
                </button>
                <button className='btn btn-success btn-sm w-40 ms-2' onClick={acceptChanges}>
                  Confirm
                </button>
              </div>
              : location?.state?.club?.label ?
                <button className='bet-place-btn' disabled={!isValidCombination || warnMessage} onClick={placeBet}>
                  {rootLoader && activeClub?.bet_delay_toggle ? <span>Placing in <DelayedTimer timer={activeClub?.bet_delay_seconds}/> sec. </span> : "Place Bet"}
                </button>
                : <div className='mb-2'>
                  <button className='btn btn-sm w-40 btn-orange text-white' onClick={() => document.getElementById("signup_1").click()}>
                    Signup
                  </button>
                  <button className='btn btn-sm w-40 btn-orange text-white ms-2' onClick={() => document.getElementById("login_1").click()}>
                    Login
                  </button>
                </div>
            }
            <button className='remove-selection-btn' onClick={removeAllBets}>
              Remove selections
            </button>
          </div>
          : <div className='text-center text-danger'>
            Please select minimum two bets
          </div>}
      </div>
    </>
  )
}


export default BetBottom