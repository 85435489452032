import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from '../../../../../../../Sportbook/Reports/Bets/ExpandableView';
import moment from 'moment'
import { PLAYER_BET_HISTORY } from '../../../../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../../../../API-Handle/services/auth_curd';
import { APP_OFFSET } from '../../../../../../../../constant';
import Spinner from '../../../../../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../../../../../Utils/sportsbook';

const columns = [
    {
        name: 'Participant',
        selector: row => row.participant,
        sortable: true,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
        sortable: true
    },
    {
        name: 'To Win',
        selector: row => row.winnings,
        sortable: true
    },
    {
        name: 'Bet Type',
        selector: row => row.bet_type,
        sortable: true
    },
    {
        name: 'Placed Date',
        selector: row => row.final_date,
        sortable: true,
        minWidth: "230px"
    }
];

export default function MemberUnsettled({ club, item }) {
    const [preparedData, setPreparedData] = useState(null)
    const [unsettledTotal, setUnsettledTotal] = useState(0)
    
    const getBets = async () => {
        setPreparedData(null)
        let club_id = club?.id;
        if (!club_id) return;
        let request_url = PLAYER_BET_HISTORY + club_id + `?bet_type=UNSETTLED&player_id=${item?.id}`;
        let bets = await getValuebet(request_url);
        prepareData(bets);

        let unsettled_total = {
            participant: `Total`,
            risking: 0,
            winnings: 0,
            bet_type: "-",
            final_date: "-",
        }
        bets.forEach(bet => {
            unsettled_total.risking += bet.risking;
            unsettled_total.winnings += bet.winnings;
        })
        unsettled_total.risking = unsettled_total.risking.toFixed(2)
        unsettled_total.winnings = unsettled_total.winnings.toFixed(2)
        setUnsettledTotal(unsettled_total)
    }

    const prepareData = async (data) => {
        if (data) {
            for (let row of data) {
                row.participant = getBetslipTitle(row);
                row.final_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"
            }
            setPreparedData(data)
        }
    }

    useEffect(() => {
        getBets()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView isModal={true} />}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={unsettledTotal}
                /> :
                <Spinner />}
        </>
    )
}
