import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalBody
} from "reactstrap";
import Plan from './Plan';
import { getValuebet, GET_LEVEL_PRICING_URL } from '../../../../API-Handle/services/auth_curd';

export default function UpgradeLevel() {
    const [levelModal, setLevelModal] = useState(false);
    const [levels, setLevels] = useState([]);

    const toggleLevelModal = () => {
        setLevelModal(!levelModal)
    }

    const getLevelList = async () => {
        let res = await getValuebet(GET_LEVEL_PRICING_URL)
        if(res){
            setLevels(res)
        }
    }

    useEffect(() => {
        getLevelList()
    }, [])

    return (
        <div>
            <Modal isOpen={levelModal} toggle={toggleLevelModal} className="modal-dialog-centered modal-lg">
                <div className="modal-header">
                    <h5 className="modal-title my-0"> Upgrade Level </h5>
                    <button onClick={toggleLevelModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <ModalBody>
                    <div className='row coin-modal-margin overflow-x'>
                        <table className="table text-center table-custom-dark">
                            <thead>
                                <tr>
                                    <th scope="col">Level</th>
                                    <th scope="col">Max Clubs</th>
                                    <th scope="col">P2P Messaging</th>
                                    <th scope="col">P2P Coin Transfer</th>
                                    <th scope="col">Days</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody className="mx-2">
                                {levels.map((item) => {
                                    return (
                                        <Plan key={item.id} item={item} toggleLevelModal={toggleLevelModal}/>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
            </Modal>
            <button className="btn btn-danger btn-sm btn-orange w-lg my-3" onClick={toggleLevelModal} >
                Upgrade Now
            </button>
        </div>
    )
}
