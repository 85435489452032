import React, { useContext, useEffect, useState } from 'react'
import BetCard from './BetCard'
import BetBottom from './BetBottom'
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';
import { WinToRisk } from '../../../../../Utils/sportsbook';

export default function Index({ betDetails }) {
    const { getStraightBetMaxLimit } = useContext(ValuebetContext);
    const [totalMaxRisking, setTotalMaxRisking] = useState(0);

    useEffect(()=>{
        let total_max_risking = 0;
        betDetails?.bets.forEach(item => {
            let max_limit = getStraightBetMaxLimit(item);
            if (item.odd_money < 0) {
                total_max_risking += WinToRisk(item.odd_money, max_limit);
            }else{
                total_max_risking += max_limit;
            }
        });
        setTotalMaxRisking(total_max_risking);
        // eslint-disable-next-line
    }, [betDetails])
    return (
        <>
            {
                betDetails?.bets.map(item => (
                    <BetCard item={item} key={item.id} totalMaxRisking={totalMaxRisking} />
                ))
            }
            <BetBottom/>
        </>
    )
}
