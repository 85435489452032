import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import CloseIcon from "@mui/icons-material/Close";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { OFFLINE_ODD } from "../../../../constant";
import { RiskToWin, WinToRisk } from "../../../../Utils/sportsbook";
import {
  updateBets,
} from "../../../../redux/features/polyMarketSlice";
import { useDispatch, useSelector } from "react-redux";

function BetCard({ item, betDetails, totalMaxRisking }) {
  const dispatch = useDispatch();
  const { activeClub } =
    useContext(ValuebetContext);
  const [warnMessage, setWarnMessage] = useState(false);
  const polybetDetails = useSelector(
    (state) => state.polyMarket.polybetDetails
  );

  useEffect(() => {
    let message = false;
    let min_limit = 1;
    let max_limit = 1000;

    if (item?.risking) {
      if (item.avg_price > 0) {
        if (max_limit < item?.risking || item?.risking < min_limit) {
          message = `Risking value must be greater-than or equal to ${min_limit} and less-than or equal to ${max_limit}.`;
        }
      } else {
        let risk_max_limit = WinToRisk(item.avg_price, max_limit);
        risk_max_limit = Number(risk_max_limit.toFixed(2));
        if (risk_max_limit < item?.risking || item?.risking < min_limit) {
          message = `Risking value must be greater-than or equal to ${min_limit} and less-than or equal to ${risk_max_limit}.`;
        }
      }
    }
    setWarnMessage(message);
    // eslint-disable-next-line
  }, [activeClub, item]);

  const calculateStraightsBet = async (risking, to_win) => {
    let bets = JSON.parse(JSON.stringify(betDetails.bets));
    for (let bet of bets) {
      if (bet.odd_id === item.odd_id) {
        let risk_val = "";
        let win_val = "";
        if (risking !== null && risking !== "") {
          risk_val = Math.abs(risking);
          if (bet.avg_price < 0) {
            win_val = RiskToWin(bet.avg_price, risk_val);
          } else if (bet.avg_price > 0) {
            win_val = RiskToWin(bet.avg_price, risk_val);
          }
          win_val = Number(win_val.toFixed(2));
        }

        if (to_win !== null && to_win !== "") {
          win_val = Math.abs(to_win);
          if (bet.avg_price < 0) {
            risk_val = WinToRisk(bet.avg_price, win_val);
          } else if (bet.avg_price > 0) {
            risk_val = WinToRisk(bet.avg_price, win_val);
          }
          risk_val = Number(risk_val.toFixed(2));
        }

        bet.risking = risk_val;
        bet.to_win = win_val;
        bet.init_risk = risk_val;
        bet.init_win = win_val;

        break;
      }
    }
    // setBetDetails({ ...betDetails, bets })
    dispatch(updateBets(bets));
  };

  const hitMaxRisk = () => {
    let max_limit = 1000;
    if (totalMaxRisking > activeClub?.user_chips?.player_chips) {
      let new_risk = (
        activeClub?.user_chips?.player_chips / betDetails.bets.length
      ).toFixed(2);
      if (new_risk > 1 && betDetails.bets.length > 1)
        new_risk = (new_risk - 0.05).toFixed(2);
      calculateStraightsBet(new_risk, null);
    } else if (item.avg_price > 0) {
      calculateStraightsBet(max_limit, null);
    } else {
      calculateStraightsBet(null, max_limit);
    }
  };

  const removeSelectedBet = (item) => {
    const newBet = polybetDetails.bets.filter(
      (bet) => bet.odd_id !== item.odd_id
    );
    dispatch(updateBets(newBet));
  };

  return (
    <Card className="bet-card">
      <div className="bet-card-header px-2 py-2 align-items-center">
        <div className="font-size-13px text-white">{item.eventName}</div>
        <div
          className="ms-2"
          role={"button"}
          onClick={() => removeSelectedBet(item)}
        >
          <CloseIcon style={{ fontSize: 15 }} />
        </div>
      </div>
      <CardBody className="p-2">
        <div className="mb-1">
          {item.avg_price !== OFFLINE_ODD ? (
            <small
              className={
                item.init_sub_title !== item.sub_title ? "text-danger" : ""
              }
            >
              {item.sub_title}
            </small>
          ) : (
            <small className={"text-danger"}>
              {
                "Currenly, This odd is no longer available! Please remove this ticket."
              }
            </small>
          )}

          {item.init_sub_title !== item.sub_title && (
            <div className={"font-size-10px"}>
              <del>{item.init_sub_title}</del>
            </div>
          )}

          {item.avg_price !== OFFLINE_ODD && (
            <>
              <div className="d-flex justify-content-center my-3 px-10per">
                <div className="me-2">
                  <input
                    type="number"
                    value={item?.risking || ""}
                    min="0"
                    className={`form-control form-control-sm bg-vb-dark border border-danger ${
                      item?.init_risk && item?.init_risk !== item?.risking
                        ? "text-danger"
                        : "text-white"
                    } card-input`}
                    placeholder="Risking"
                    onChange={(e) =>
                      calculateStraightsBet(e.target.value, null)
                    }
                  />
                  {item?.init_risk && item?.init_risk !== item?.risking && (
                    <div className="font-size-12px ms-2 ps-1">
                      <del>{item.init_risk}</del>
                    </div>
                  )}
                </div>
                <div className="ms-2">
                  <input
                    type="number"
                    value={item?.to_win || ""}
                    min="0"
                    className={`form-control form-control-sm bg-vb-dark border border-danger ${
                      item.init_win && item.init_win !== item?.to_win
                        ? "text-danger"
                        : "text-white"
                    } card-input`}
                    placeholder="To win"
                    onChange={(e) =>
                      calculateStraightsBet(null, e.target.value)
                    }

                    // onBlur={handleBlur}
                  />
                  {item?.init_win && item?.init_win !== item?.to_win && (
                    <div className="font-size-12px ms-2 ps-1">
                      <del>{item.init_win}</del>
                    </div>
                  )}
                </div>
              </div>

              {warnMessage ? (
                <div className="text-center text-danger">
                  <small>{warnMessage}</small>
                </div>
              ) : null}
              <div className="text-center">
                <small>Enter amount in box or select below</small>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  className="btn-outline-box mt-3 straigth-max"
                  onClick={hitMaxRisk}
                >
                  MAX
                </button>
              </div>
            </>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default BetCard;
