import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Card } from "@mui/material";
import { useParams } from "react-router";
import { styles } from "../../Css/styles";
import {formatToMillions,formatValue} from "../../../../Utils/valuemakets"
import MarketList from "../Common/MarketList";
import NavigationBar from "../../../../Components/SportsbookHeader/NavigationBar";
import TradeCard from "./TradeCard";
import { BASEURL } from "../../../../API-Handle/config";
import Bets from '../../Bets'
import Sidebar from "../Markets/Sidebar";
import MarketGraph from "./Graph";


const Details = () => {
  const param = useParams();
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);


  const valueType = (item) => {
    setSelectedOption(prev => (prev === item ? null : item));
  }

  const params = {
    limit: 20,
    active: true,
    archived: false,
    closed: false,
    order: "volume24hr",
    ascending: false,
    offset: 0,
  };

  const fetchEvents = async (apiURL,params) => {
      setLoading(true);
      setError(null);
      const baseUrl = BASEURL.concat(apiURL);
      const queryString = new URLSearchParams(params)?.toString();
      try {
        const response = await fetch(`${baseUrl}?${queryString}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

  useEffect(() => {
    fetchEvents(`/sportsbook/gamma/events/${param.slug}`, params);
  }, [param]);

  return (
    
    <React.Fragment>
    <NavigationBar />
    <div className="sportbook-container-fluid">
      <Sidebar/>
      
      <Grid
      container
      spacing={2}
      mt="65px"
      ml={0}
      sx={{
        maxWidth:'72%'
      }}
      
    >
      
      <Grid
        container
        spacing={1}
        my={1}

        sx={{
          ml: {
            xs: 0,
            sm: 0,
            lg: 1,
            md: 1,
            xl: 2,
          },
          overflowY: "auto",
          pr: 1,
          width:{
            xs:'100%'
          }
        }}
      >
    
    
      <Box
        sx={{
          bgcolor: "#0b0b0b",
          color: "#fff",
          minHeight: "100vh",
          p: 3,
          width:'100%'
        }}
      >

        <Box sx={{ ...styles.top, mb: 3 }}>
          <img
            onClick={() => setSelectedOption(events.id)}
            src={events.image}
            style={{ maxWidth: "80px", maxHeight: "80px",minHeight:'80px', cursor: "pointer" }}
          />
          <Typography sx={{ fontSize: "22px",fontFamily:'inherit' }}>{events.title}</Typography>
        </Box>

        <Typography variant="subtitle1" gutterBottom sx={{fontFamily:'inherit'}}>
          $ {formatToMillions(events.volume)} VoL | Feb 1, 2025
        </Typography>

        <Grid container spacing={3}>
          {/* Left Panel - Graph Section */}
          <Grid item xs={12} md={12}>
            <Card sx={{  p: 2,background:'#161313' }}>
              <CardContent>
                <Typography variant="h6">Graph Placeholder</Typography>
                <Divider sx={{ my: 2 }} />
                {/* Replace this with the graph component */}
                <Box
                  sx={{
                    height: 200,
                    bgcolor: "#000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 2,
                  }}
                >
                  <MarketGraph/>
                </Box>
              </CardContent>
            </Card>
            <Box sx={{ mt: 3 }}>
              {events?.markets &&
                events?.markets.map((row, index) => (
                  <Card
                    key={index}
                    sx={{ bgcolor: "#161313", mt: index === 0 ? 0 : 2 }}
                  >
                    <CardContent sx={{pb:0,pt:3,px:3}}>
                      <MarketList
                        key={index}
                        marketId={events.id}
                        image={row.image}
                        name={row.groupItemTitle}
                        outcome={row.outcomes}
                        price={row.outcomePrices}
                        valueType={valueType}
                        uiType={'OUTSIDE'}
                      />
                    </CardContent>
                  </Card>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Grid>

      

    </Grid>

      <Bets />
    
      
    </div>
  </React.Fragment>

      
    
  );
};

export default Details;
