import React, { useState } from 'react'
import Unsettled from './Unsettled'
import Settled from './Settled'

export default function Index() {
    const [betType, setBetType] = useState("UNSETTLED");

    return (
        <div>
            <div className="btn-group" role="group">
                <button type="button" className={betType == 'UNSETTLED' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('UNSETTLED')}>Pending</button>
                <button type="button" className={betType == 'SETTLED' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('SETTLED')}>Settled</button>
            </div>
            <div className="no-tbl-header">
                {betType == 'UNSETTLED' ?
                    <Unsettled /> :
                    <Settled />
                }
            </div>
        </div>
    )
}