import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "../../Css/styles";
import { useSelector } from "react-redux";

const SliderButtons = ({data,handleSlugChange,slug}) => {
  const scrollContainerRef = useRef(null);
  const isFirstRender = useRef(true);
  const isCalling = useSelector(
    (state) => state.polyMarket.isCalling
  );
  
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
 
    const handleScroll = (direction) => {
      const container = scrollContainerRef.current;
      if (container) {
        const scrollAmount = direction === "left" ? -150 : 150;
        container.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    };
  
    const updateScrollButtonsState = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const { scrollLeft, scrollWidth, offsetWidth } = container; 
        setCanScrollLeft(scrollLeft > 0);
        setCanScrollRight(scrollLeft + offsetWidth < scrollWidth);
      }
    };


    useEffect(() => {
      const container = scrollContainerRef.current;
  
      if (container) {
        container.addEventListener("scroll", updateScrollButtonsState);
        updateScrollButtonsState();
  
        return () => {
          container.removeEventListener("scroll", updateScrollButtonsState);
        };
      }
    }, [scrollContainerRef,data]);


    useEffect(() => {
    
      if (isFirstRender.current) {
        // Skip the first render
        isFirstRender.current = false;
        return;
      }
    
      if (data && isCalling) {
        console.log(data[0].slug);
        handleSlugChange(data[0].slug);
      }
    }, [data]);


  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1,maxWidth:'100%' }}>
      <IconButton
        onClick={() => handleScroll("left")}
        disabled={!canScrollLeft}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Box
        ref={scrollContainerRef}
        sx={styles.buttonScroll}
      >
          <Button
    variant="contained"
    onClick={() => handleSlugChange("Reset")} // Handle "all" click
    sx={{
      flexShrink: 0,
      background: slug === "all" ? "#e03f00" : "#252222",
      color: "#fff",
      fontFamily: 'inherit',
      textTransform: "none",
      '&:hover': {
        background: "#e03f00"
      }
    }}
  >
    All
  </Button>
        {data?.map((item, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={()=> handleSlugChange(item.slug)}
            sx={{
              flexShrink: 0,
              background:slug === item.slug ? "#e03f00" : "#252222",
              color:"#fff",
              fontFamily:'inherit',
              textTransform: "none",
              '&:hover':{
                background:"#e03f00"
              }
            }
          }
          >
            {item.label}
          </Button>
        ))}
      </Box>
      <IconButton
        onClick={() => handleScroll("right")}
        disabled={!canScrollRight}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default SliderButtons;