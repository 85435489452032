import React, { useContext, useEffect, useState } from 'react'
import BetCard from './BetCard'
import BetBottom from './BetBottom'
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';
import { WinToRisk } from '../../../../Utils/sportsbook';
import { useSelector } from 'react-redux';

export default function Index({ betDetails }) {
   
    const [totalMaxRisking, setTotalMaxRisking] = useState(0);

    useEffect(()=>{
        let total_max_risking = 0;
        betDetails?.bets.forEach(item => {
            let max_limit = 1000;
            if (item.avg_price < 0) {
                total_max_risking += WinToRisk(item.avg_price, max_limit);
            }else{
                total_max_risking += max_limit;
            }
        });
        setTotalMaxRisking(total_max_risking);
        // eslint-disable-next-line
    }, [betDetails])

    return (
        <>
            {
                betDetails?.bets.map(item => (
                    <BetCard item={item} 
                    key={item.odd_id} 
                    totalMaxRisking={totalMaxRisking} 
                    betDetails={betDetails}
                    />
                ))
            }
            <BetBottom details={betDetails}/>
        </>
    )
}
