import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../Components/UserHeader'
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { Row } from 'reactstrap';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import MonthlySummary from './MonthlyWinners'
import WeeklyWinners from './WeeklyWinners '
import DailyWinners from './DailyWinners'

function Index() {
    const location = useLocation()
    const navigate = useNavigate();
    const { activeClub, setActiveClub } = useContext(ValuebetContext)
    const [reportType, setReportType] = useState(null);

    useEffect(() => {
        let club = location?.state?.club;
        if (!club) {
            navigate("/dashboard")
        } else {
            setActiveClub(club)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        if (activeClub){
            let active_tab = activeClub.leaderboard_status.daily ? "Daily" : activeClub.leaderboard_status.weekly ? "Weekly" : activeClub.leaderboard_status.monthly ? "Monthly" : null;
            setReportType(active_tab);
        }
        // eslint-disable-next-line
    }, [activeClub])

    return (
        <div>
            <Header />
            <div className="sec-header container-fluid">
                <Row>
                    <div className="d-flex py-2 justify-content-start align-items-center">
                        <h6 className="sec-header-title font-size-14px">
                            {`${activeClub?.name?.toUpperCase()}'s Leaderboard`}
                        </h6>
                    </div>
                </Row>
            </div>
            <div className='layout-mt-112 fill-view'>
                <div className="container-fluid mt-4">
                    <div className='border rounded border-danger'>
                        <div className="card mb-0 bg-vb-dark overflow-auto text-white">
                            <div className="card-body">
                                <div>
                                    <div className="btn-group" role="group">
                                    {activeClub?.leaderboard_status.daily && 
                                        <button type="button" className={reportType === 'Daily' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Daily')}>Daily</button>}
                                        
                                    {activeClub?.leaderboard_status.weekly && 
                                        <>
                                            <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                                            <button type="button" className={reportType === 'Weekly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Weekly')}>Weekly</button>
                                        </>}
                                    {activeClub?.leaderboard_status.monthly && 
                                        <>
                                        <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                                        <button type="button" className={reportType === 'Monthly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setReportType('Monthly')}>Monthly</button>
                                        </>}
                                    </div>
                                    {activeClub && <div className="no-tbl-header">
                                        {reportType === 'Daily' ?
                                            <DailyWinners club={activeClub}/> :
                                                reportType === 'Weekly' ?
                                                    <WeeklyWinners club={activeClub}/> :
                                                        <MonthlySummary club={activeClub}/>
                                        }
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Index;