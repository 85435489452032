import React from 'react';
import { Grid, Card, CardContent, Box, Skeleton } from '@mui/material';
import { styles } from '../../Pages/Polymarkets/Css/styles';

export default function SkeletonCard(toggleFilter)  {
    return (
       <Grid
                       item
                       lg={toggleFilter ? 4 : 4 }
                xl={6}
                md={6}
                sm={6}
                xs={12}
                       sx={styles.box}
                     >
            <Card sx={styles.cardStyle}>
                <CardContent sx={{ p: 2 }}>
                    <Box sx={styles.top}>
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="text" sx={{ width: '60%' }} />
                    </Box>
                    <Box sx={styles.scrollbar}>
                        {Array.from({ length: 3 }).map((_, index) => (
                            <Box key={index} sx={{ mb: 1 }}>
                                <Skeleton variant="text" sx={{ width: '80%' }} />
                                <Skeleton variant="rectangular" sx={{ width: '100%', height: 20 }} />
                            </Box>
                        ))}
                    </Box>
                </CardContent>
                <Box sx={styles.footPart}>
                    <Skeleton variant="rectangular" sx={{ height: 40, width: '100%' }} />
                </Box>
            </Card>
        </Grid>
    );
};
