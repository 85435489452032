import React from 'react'
import { useNavigate } from 'react-router-dom'
import SmSpinner from '../../../../../Components/CustLoader/SmSpinner';

export default function Winners({ club, data }) {
    const navigate = useNavigate();

    const playerReport = async (row) => {
        if (club.leaderboard_status.report_toggle || ["Owner", "Manager"].includes(club.label)){
            navigate("/players-report", { state:{ member: { id: row.uid, username: row.username }, club: club } })
        }
    }

    return (
        <div className='winner-table'>
            {data ? data?.report_data?.length ?
                <>
                    <div className="d-flex justify-content-between">
                        <label className='bg-dark p-1 text-light rounded font-size-11px' > {data?.report_time}</label>
                        <label className='p-1 text-light rounded border border-dark font-size-11px' > Total Players : {data?.total_players}</label>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-custom-dark text-nowrap table-borderless mb-0">
                            <thead>
                                <tr className='font-size-11px'>
                                    <td className='py-0 ps-0'>Rank</td>
                                    <td className='py-0'>Result</td>
                                    <td className='p-0'>W-L</td>
                                </tr>
                                <tr>
                                    <td className='px-0 pb-1 pt-0'><div className='horizantal-line' /></td>
                                    <td className='px-0 pb-1 pt-0'><div className='horizantal-line' /></td>
                                    <td className='px-0 pb-1 pt-0'><div className='horizantal-line' /></td>
                                </tr>
                            </thead>
                            <tbody className='font-size-10px'>
                                {data.report_data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='py-0 ps-0'><span role="button" onClick={() => playerReport(item)}>{index+1}. {item.username?.toUpperCase()}</span></td>
                                            <td className='py-0'><span className="letter-space"> <span className={item.total_result < 0 ? "vb-text-orange" : "text-success"}>{Math.round(item.total_result)}</span></span></td>
                                            <td className='p-0'><span className="letter-space">{`${item.win_count}-${item.loss_count}`}</span></td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </> :
                <div className='text-center text-light py-3 font-size-10px'>
                    No Report Found!
                </div> :
                <SmSpinner my={0} py={3} />}
        </div>
    )
}
