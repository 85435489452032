import React, { useCallback, useState } from "react";
import { Box, IconButton, InputBase, MenuItem, Select, ToggleButton, ToggleButtonGroup } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import ListIcon from "@mui/icons-material/List";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { styles } from "../../Css/styles";
import { setFilterToggle, setMobileScreen } from "../../../../redux/features/polyMarketSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomDropdown from "./CustomDropdown";

const SearchBar = ({handleSearch,handleViewChange,view,handleSort,order}) => {
  const dispatch = useDispatch();
  const toggleFilter = useSelector(
    (state) => state.polyMarket.toggleFilter
  );

  const debounce = (func, delay) => {
    let timer;
    return (...args) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = useCallback(
    debounce((value) => handleSearch(value), 500),
    [])

    const toggle = () => {
      dispatch(setFilterToggle(!toggleFilter)); // Toggling the value
      dispatch(setMobileScreen("step1"))
      
    };

  return (
    <Box sx={styles.searchBar}>
      <IconButton onClick={toggle}>
        <FilterListIcon  sx={{ color: "white" }} />
      </IconButton>
      <Box
        sx={styles.searchWrapper}
      >
        <SearchIcon  sx={{ color: "white", marginRight: 1 }} />
        <InputBase
          placeholder="Search by market"
          onChange={(e) => debouncedSearch(e.target.value)}
          inputProps={{ "aria-label": "search by market" }}
          sx={{ color: "white", flex: 1 }}
        />
      </Box>
      <CustomDropdown handleSort={handleSort} order={order}/>
      {/* <ToggleButtonGroup
        value={view}
        exclusive
        onChange={handleViewChange}
        sx={styles.toggler}
      >
        <ToggleButton value="grid" sx={{ border: "none" }}>
          <GridViewIcon />
        </ToggleButton>
        <ToggleButton value="list" sx={{ border: "none" }}>
          <ListIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <IconButton>
        <StarBorderIcon sx={{ color: "white" }} />
      </IconButton> */}
    </Box>
  );
};

export default SearchBar;