import React, { useCallback, useEffect, useState } from "react";
import ChatDropdown from "../../../Components/CustInputs/ChatDropdown";
import { useSelector } from "react-redux";
import { getValuebet, SEARCH_CLUB_MEMBER } from "../../../API-Handle/services/auth_curd";
import { debounce } from "../../../Utils/comman";

const FindMembers = ({ onChange, selecetedItems, isMulti=false, exincludes=[] }) => {
  const [dropDownData, setDropDownData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startSearching, setStartSearching] = useState(false);
  const { selectedClub } = useSelector((state) => state.clubChat);

  const getMembers = async (query, search_force=false) => {
      if (!search_force && !query) return;
      setStartSearching(true);
      let URL = `${SEARCH_CLUB_MEMBER}${selectedClub?.id}?search_query=${query}`;
      let res = await getValuebet(URL)
      setStartSearching(false);
      res.forEach(item => {
        item['label'] = item.username
        item['value'] = item.id
      });
      setDropDownData(res);
  };

  const fetchMembers = useCallback(debounce((qry) => getMembers(qry), 500), []);

  useEffect(() => {
    getMembers('', true);
    // eslint-disable-next-line
  }, [])

  useEffect(() => {  
    if (!(dropDownData?.length && exincludes) ) return;

    const filtered_members = dropDownData.filter(
      (member) => !exincludes.some((m) => m.id === member.id)
    );

    setFilteredData((prevData) => {
      if (JSON.stringify(prevData) === JSON.stringify(filtered_members)) {
        return prevData; // Avoid unnecessary updates
      }
      return filtered_members;
    });
  }, [exincludes, dropDownData]);

  return (
    <>
        <ChatDropdown
          placeholder="Search"
          options={filteredData}
          value={selecetedItems}
          onChange={onChange}
          onInputChange={fetchMembers}
          isDisabled={false}
          isMulti={isMulti}
          isLoading={startSearching}
          required={false}
        />
    </>
  );
};

export default FindMembers;
