import React from 'react'
import MlbTable from './MlbTable';
import FootballTable from './FootballTable';
import NbaTable from './BasketBall/NbaTable';
import HockeyTable from './Hockey/HockeyTable';

const renderTable=(item)=>{
    switch(item.sport_name){
        case 'BASEBALL':
            return <MlbTable item={item}/>;
        case 'FOOTBALL':
          return <FootballTable item={item}/>
        case 'BASKETBALL':
          return <NbaTable item={item}/>
        case 'HOCKEY':
          return <HockeyTable item={item}/>
        
        
        default:
            return null; 
    }
}

const ScoreTable = ({item}) => {
  return (
    <div>{renderTable(item)}</div>
  )
}

export default ScoreTable;