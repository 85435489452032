import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { CLUB_BET_HISTORY } from '../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import Spinner from '../../../../../Components/CustLoader/Spinner';
import ExpView from './ExpView.js';

const columns = [
    {
        name: 'Player',
        selector: row => row.username,
        sortable: true,
    },
    {
        name: `Risk`,
        selector: row => row.risking.toFixed(2),
        sortable: true
    },
    {
        name: `To Win`,
        selector: row => row.winnings.toFixed(2),
        sortable: true
    }
];

export default function Index() {
    const [data, setData] = useState(null)
    const [dynamicColumns, setDynamicColumns] = useState(columns);
    const location = useLocation()

    const getBets = async () => {
        setData(null)
        let club_id = location?.state?.club?.id;
        if (!club_id) return;

        let request_url = CLUB_BET_HISTORY + club_id + "?bet_type=UNSETTLED";
        let bets = await getValuebet(request_url);
        if (bets) {
            calculateTotals(bets);
        }
    }
    
    const calculateTotals = (bets) => {
        let totalRisking = 0;
        let totalWinnings = 0;
        bets.forEach(bet => {
            totalRisking += bet.risking;
            totalWinnings += bet.winnings;
        });
        columns[1].name = <div className="text-nowrap">Risk ({totalRisking.toFixed(2)})</div>;
        columns[2].name = <div className="text-nowrap">To Win ({totalWinnings.toFixed(2)})</div>;
        setDynamicColumns(columns);
        setData(bets);
    }

    useEffect(() => {
        getBets()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {data ?
                <DataTable
                    columns={dynamicColumns}
                    data={data}
                    expandableRows
                    expandableRowsComponent={<ExpView />}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={undefined}
                /> :
                <Spinner />}
        </>
    )
}
