import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { getValuebet, GET_ALL_PLAYER_SUMMARY } from '../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CustomMonthPicker from '../../../../Components/Calendar/CustomMonthPicker';
import Spinner from '../../../../Components/CustLoader/Spinner';

const columns = [
    {
        name: 'Player',
        selector: row => row.username,
        sortable: true,
        minWidth:"135px"
    },
    {
        name: 'Beginning Of Month',
        selector: row => row.starting_balance,
        sortable: true,
    },
    {
        name: 'Player Results',
        selector: row => row.win_loss,
        sortable: true,
    },
    {
        name: 'Chips In/Out',
        selector: row => row.in_out,
        sortable: true,
    },
    {
        name: 'Unsettled',
        selector: row => row.unsettled,
        sortable: true,
    },
    {
        name: 'Player Chips',
        selector: row => row.balance,
        sortable: true,
    }
];

export default function MonthlySummary() {
    const [isLoading, setIsLoading] = useState(false);
    const [month, setMonth] = useState(null);
    const [total, setTotal] = useState({});
    const [summary, setSummary] = useState([]);
    const [allSummary, setAllSummary] = useState([]);
    const [isActiveOnly, setIsActiveOnly] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (month) getPlayersSummary()
        // eslint-disable-next-line
    }, [month])

    const getPlayersSummary = async () => {
        let club_id = location?.state?.club?.id;
        if (!club_id) return;
        
        setIsLoading(true);
        let res = await getValuebet(`${GET_ALL_PLAYER_SUMMARY + club_id}?report_time=${month}&report_type=Monthly`)
        if (res) {
            res.forEach((item) => {
                item['in_out'] = item['in_out'].toFixed(2);
                item['win_loss'] = item['win_loss'].toFixed(2);
                item['unsettled'] = item['unsettled'].toFixed(2)
                item['balance'] = item['balance'].toFixed(2)
            })
            setAllSummary(res);
            let total_in_out = 0;
            let total_win_loss = 0;
            let total_unsettled = 0;
            for (let hist of res) {
                total_in_out += Number(hist['in_out']);
                total_win_loss += Number(hist['win_loss']);
                total_unsettled += Number(hist['unsettled']);
            }

            let total_obj = {
                username: "Total",
                starting_balance: "-",
                win_loss: total_win_loss.toFixed(2),
                in_out: total_in_out.toFixed(2),
                unsettled: total_unsettled.toFixed(2),
                balance: "-"
            }
            setTotal(total_obj)
        }
        setIsLoading(false);
    }

    useEffect(() => {
        const activePlayer = allSummary.filter(item => {
            return item.win_loss > 0;
        });
        if (isActiveOnly) {
            setSummary(activePlayer)
        }
        else {
            setSummary(allSummary)
        }
    }, [isActiveOnly, allSummary])

    const playerReport = async (row) => {
        navigate(
            "/players-report",
            {state: { member: { id: row.user, username: row.username }, club: location?.state?.club }}
        )
    }

    return (
        <div>
            <div className='inline-datepicker-container'>
                <CustomMonthPicker getMonth={setMonth} className={"settled-datepicker"} />
            </div>
            <div className='float-end' >
                <label className='text-light'>
                    Active Players Only
                    <input
                        type="checkbox"
                        checked={isActiveOnly}
                        onChange={(e) => setIsActiveOnly(e.target.checked)}
                    />
                </label>
            </div>
            {!isLoading ?
                <div className="no-tbl-header">
                    <DataTable
                        columns={columns}
                        data={summary}
                        theme="dark"
                        dense={true}
                        header={false}
                        pointerOnHover={true}
                        highlightOnHover={true}
                        onRowClicked={playerReport}
                        footer={total}
                    />
                </div> :
                <Spinner />}
        </div>
    )
}
