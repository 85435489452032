import React from 'react'
import {
    Modal,
    ModalBody,
    Button,
  } from 'reactstrap';
import CustomDropdown from '../../../../../Components/CustInputs/ChatDropdown';
import { useSelector, useDispatch } from 'react-redux';
import { createChannel, toggleAddChannel } from '../../../../../redux/services/chatServices';
import { CHANNELTYPE } from '../../../../../constant';
import CustomForm from '../../../../../Components/CustComp/CustomForm';
import FindMembers from '../../../comman/FindMembers';

function AddChannel() {
    const dispatch = useDispatch();
    const {isAddChannel, selectedClub, isloading} = useSelector(state => state.clubChat)
    const [selecetedUser, setSelectedUsers] = React.useState([]);
    const [channel, setChannel] = React.useState("")
    const [errorName, setErrorName] = React.useState("")
    const [errorState, setErrorState] = React.useState("")

    const toggle = () =>{
        dispatch(toggleAddChannel(isAddChannel))
    }
    const onSubmit = () =>{
        setErrorState(null)
        if(selecetedUser.length === 0){
            setErrorState("Please Select at least 1 Member")
        }else{
            let usersData = [];
            selecetedUser.forEach((item)=>{
                usersData.push(item.value)
            })
            dispatch(createChannel(selectedClub.id, channel, usersData, CHANNELTYPE.group))
        }
        
    }
  return (
    <>
        <Modal isOpen={isAddChannel} toggle={toggle} className="modal-dialog-centered modal-md">
            <div className="modal-header bg-vb-dark">
            <h5 className="modal-title my-0"> Add Channel</h5>
            <button onClick={toggle} type="button" className="text-white btn-close" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <ModalBody>
        <CustomForm onSubmit={onSubmit}>
            <div className="form-group my-3">
            <input 
                type="text" 
                placeholder='Channel Name' 
                className="form-control bg-vb-dark text-white" 
                value={channel} 
                onChange={(e) => setChannel(e.target.value)} 
                required
                minLength={3}
                onInvalid={(e) => setErrorName(e.target.validationMessage)}
            />
            <div className="invalid-feedback">
                {errorName}
            </div>
            </div>
            <div className="form-group">
            <FindMembers onChange={setSelectedUsers} selecetedItems={selecetedUser} isMulti={true} />
            <div className="text-danger font-size-13px mt-1">
                {errorState}
            </div>
            </div>
            <div className="text-end mt-4">
                <Button type='submit' disabled={isloading} className='rename-button me-2' color="success" >
                    {" "}
                   {isloading ? "Loading":"Add"}{" "}
                </Button>
                <Button disabled={isloading} className='rename-button ' color="secondary" onClick={toggle}>
                    {" "}
                    {isloading ? "Loading":"Cancel"}{" "}
                </Button>
            </div>
        </CustomForm>
        </ModalBody>
        </Modal>
    </>
  )
}

export default React.memo(AddChannel)
