import React from 'react'
import SmSpinner from '../../../../../../Components/CustLoader/SmSpinner';

export default function LimitTable({ height, loader, limits, setUpdatedLimits, isDisable, saveLimits, toggleModal }) {
    const updateAllVal = (amount, type) => {
        let new_limits = [...limits];
        new_limits.forEach((ele) => {
            if (type === "MIN_BET_ALL") { 
                ele.min_bet_all = amount;
            } else if (type === "MAX_BET_ALL") {
                ele.max_bet_all = amount;
            } else if (type === "MAX_BET_LIVE") {
                ele.max_bet_live = amount;
            } else if (type === "MAX_PARLAY_ALL") {
                ele.max_parlay_all = amount;
            } else if (type === "MAX_PARLAY_LIVE") {
                ele.max_parlay_live = amount;
            } else if (type === "MAX_PARLAY_PAYOUT") {
                ele.max_parlay_payout = amount;
            }
        })
        setUpdatedLimits(new_limits)
    }

    const updateVal = (item, val, type) => {
        let new_limits = [...limits];
        new_limits.forEach((ele) => {
            if (ele.league === item.league) {
                if (type === "MIN_BET_ALL") {
                    ele.min_bet_all = val;
                } else if (type === "MAX_BET_ALL") {
                    ele.max_bet_all = val;
                } else if (type === "MAX_BET_LIVE") {
                    ele.max_bet_live = val;
                } else if (type === "MAX_PARLAY_ALL") {
                    ele.max_parlay_all = val;
                } else if (type === "MAX_PARLAY_LIVE") {
                    ele.max_parlay_live = val;
                } else if (type === "MAX_PARLAY_PAYOUT") {
                    ele.max_parlay_payout = val;
                } else if (type === "IS_ACTIVE") {
                    ele.is_activate = val;
                } else if (type === "IS_PROPS_ACTIVE") {
                    ele.is_props_activate = val;
                } else if (type === "IS_LIVE_ACTIVE") {
                    ele.is_live_activate = val;
                } else if (type === "IS_FIRST_HALF_ACTIVE") {
                    ele.is_first_half_activate = val;
                } else if (type === "IS_SECOND_HALF_ACTIVE") {
                    ele.is_second_half_activate = val;
                } else if (type === "IS_FIRST_PERIOD_ACTIVE") {
                    ele.is_first_period_activate = val;
                }
            }
        })
        setUpdatedLimits(new_limits)
    }

    return (
        <>
            <div className="overflow-x overflow-y" style={{ maxHeight: window.innerHeight - height }}>
                <table className={`table table-custom-dark ${isDisable && "section-disable"}`}>
                    <thead>
                        <tr>
                            <th scope="col" className="align-top">Sports</th>
                            <th scope="col" className="align-top">Activate</th>
                            <th scope="col" className="align-top">Props Bets</th>
                            <th scope="col" className="align-top">Live Bets</th>
                            <th scope="col" className="align-top">1st Half</th>
                            <th scope="col" className="align-top">2nd Half</th>
                            <th scope="col" className="align-top">1st Quarter</th>
                            <th scope="col" className="align-top">
                                Min Bet All 
                                <div>
                                    <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "MIN_BET_ALL")} />
                                </div>
                            </th>
                            <th scope="col" className="align-top text-nowrap">
                                Max Bet All
                                <div>
                                    <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "MAX_BET_ALL")} />
                                </div>
                            </th>
                            <th scope="col" className="align-top">
                                Max Bet Live
                                <div>
                                    <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "MAX_BET_LIVE")} />
                                </div>
                            </th>
                            <th scope="col" className="align-top">
                                Max Parlay All
                                <div>
                                    <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "MAX_PARLAY_ALL")} />
                                </div>
                            </th>
                            <th scope="col" className="align-top">
                                Max Parlay Live
                                <div>
                                    <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "MAX_PARLAY_LIVE")} />
                                </div>
                            </th>
                            <th scope="col" className="align-top">
                                Max Parlay Pay
                                <div>
                                    <input type="number" min={0} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount: Apply All" onChange={(e) => updateAllVal(e.target.value, "MAX_PARLAY_PAYOUT")} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {limits.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.league}</td>
                                    <td><input type="checkbox" checked={item.is_activate} onChange={(e) => updateVal(item, e.target.checked, "IS_ACTIVE")} /></td>
                                    <td><input type="checkbox" checked={item.is_props_activate} onChange={(e) => updateVal(item, e.target.checked, "IS_PROPS_ACTIVE")} /></td>
                                    <td><input type="checkbox" checked={item.is_live_activate} onChange={(e) => updateVal(item, e.target.checked, "IS_LIVE_ACTIVE")} /></td>
                                    <td><input type="checkbox" checked={item.is_first_half_activate} onChange={(e) => updateVal(item, e.target.checked, "S_FIRST_HALF_ACTIVE")} /></td>
                                    <td><input type="checkbox" checked={item.is_second_half_activate} onChange={(e) => updateVal(item, e.target.checked, "IS_SECOND_HALF_ACTIVE")} /></td>
                                    <td><input type="checkbox" checked={item.is_first_period_activate} onChange={(e) => updateVal(item, e.target.checked, "IS_FIRST_PERIOD_ACTIVE")} /></td>
                                    <td><input type="number" min={0} value={item.min_bet_all} onChange={(e) => updateVal(item, e.target.value, "MIN_BET_ALL")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                    <td><input type="number" min={0} value={item.max_bet_all} onChange={(e) => updateVal(item, e.target.value, "MAX_BET_ALL")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                    <td><input type="number" min={0} value={item.max_bet_live} onChange={(e) => updateVal(item, e.target.value, "MAX_BET_LIVE")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                    <td><input type="number" min={0} value={item.max_parlay_all} onChange={(e) => updateVal(item, e.target.value, "MAX_PARLAY_ALL")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                    <td><input type="number" min={0} value={item.max_parlay_live} onChange={(e) => updateVal(item, e.target.value, "MAX_PARLAY_LIVE")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                    <td><input type="number" min={0} value={item.max_parlay_payout} onChange={(e) => updateVal(item, e.target.value, "MAX_PARLAY_PAYOUT")} className="form-control chip-input-custom-height bg-dark text-white" placeholder="Amount" /></td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <div className="text-end mt-4">
                {loader ? <button type="button" className="btn btn-danger btn-orange btn-sm"><SmSpinner inline={true} /></button>
                    : <button type="button" disabled={isDisable} className="btn btn-danger btn-orange btn-sm" onClick={saveLimits}>Save changes</button>}
                &nbsp;
                <button onClick={toggleModal} type="button" className="btn btn-secondary btn-sm">Cancel</button>
            </div>
        </>
    )
}
