import React, { useState } from 'react'
import General from './General';
import { Modal } from 'reactstrap';
import ClubLimits from './ClubLimits'
import PlayerLimits from './PlayerLimits'
import OddLimits from './OddLimits'
// import AgentLimits from './AgentLimits'
import LockOutlined from '@mui/icons-material/LockOutlined';

export default function Setting({ item, isOpen, toggleClubSetting }) {
    const [activeTab, setActiveTab] = useState(1);
    return (
        <Modal isOpen={isOpen} toggle={toggleClubSetting} className="modal-dialog-centered modal-lg">
            <div className="modal-header bg-vb-dark">
                <h5 className="modal-title my-0"> Club Settings</h5>
                <button onClick={toggleClubSetting} type="button" className="text-white btn-close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div className="card my-0 text-start bg-vb-dark">
                <div className="card-header seperater">
                    <ul className="nav nav-tabs card-header-tabs">
                        <li className="nav-item">
                            <span className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(1)}>General</span>
                        </li>
                        <li className="nav-item">
                            <span className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(2)}>
                                {/* // TODO: Temp Unlocked */}
                                {/* {!(item?.level_details?.sport_limits) && <LockOutlined fontSize='small' className='locked-features' />} Club Limits */}
                                <div className='d-flex'>
                                    Club Limits {!(item?.level_details?.sport_limits) && <div className='d-flex'><LockOutlined fontSize='small' className='locked-features' /> <div className="text-warning font-size-10px mb-1">Preview</div></div>}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className={activeTab === 3 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(3)}>
                                {/* // TODO: Temp Unlocked */}
                                {/* {!(item?.level_details?.sport_limits) && <LockOutlined fontSize='small' className='locked-features' />} Player Limits */}
                                <div className='d-flex'>
                                    Player Limits {!(item?.level_details?.sport_limits) && <div className='d-flex'><LockOutlined fontSize='small' className='locked-features' /> <div className="text-warning font-size-10px mb-1">Preview</div></div>}
                                </div>
                            </span>
                        </li>
                        <li className="nav-item">
                            <span className={activeTab === 4 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(4)}>
                                {/* // TODO: Temp Unlocked */}
                                {/* {!(item?.level_details?.sport_limits) && <LockOutlined fontSize='small' className='locked-features' />} Odd Limits */}
                                <div className='d-flex'>
                                    Odd Limits {!(item?.level_details?.sport_limits) && <div className='d-flex'><LockOutlined fontSize='small' className='locked-features' /> <div className="text-warning font-size-10px mb-1">Preview</div></div>}
                                </div>
                            </span>
                        </li>
                        {/* <li className="nav-item">
                            <span className={activeTab === 5 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(5)}>
                                <div className='d-flex'>
                                    Agent Limits
                                </div>
                            </span>
                        </li> */}
                    </ul>
                </div>
                {activeTab === 1 ?
                    <div className="card-body overflow-x">
                        <General item={item} toggleClubSetting={toggleClubSetting} />
                    </div>
                    : activeTab === 2 ?
                        <div className="card-body overflow-x">
                            <ClubLimits club={item} toggleClubSetting={toggleClubSetting} />
                        </div>
                        : activeTab === 3 ?
                            <div className="card-body overflow-x">
                                <PlayerLimits club={item} toggleClubSetting={toggleClubSetting} />
                            </div>
                            : activeTab === 4 ?
                                <div className="card-body overflow-x">
                                    <OddLimits club={item} toggleClubSetting={toggleClubSetting} />
                                {/* </div>
                                : activeTab === 5 ?
                                    <div className="card-body overflow-x">
                                        <AgentLimits club={item} /> */}
                                    </div> : null}
            </div>
        </Modal>
    )
}
