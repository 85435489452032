import React, { useState } from 'react'
import WeeklySummary from './WeeklySummary'
import DailySummary from './DailySummary'

export default function Index() {
    const [summaryType, setSummaryType] = useState("Daily");

    return (
        <div>
            <div className="btn-group" role="group">
                <button type="button" className={summaryType == 'Daily' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setSummaryType('Daily')}>Daily</button>
                <button type="button" className="btn btn-secondary me-0 ms-0 pe-0 ps-0" disabled></button>
                <button type="button" className={summaryType == 'Weekly' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setSummaryType('Weekly')}>Weekly</button>
            </div>
            <div className="no-tbl-header">
                {summaryType == 'Daily' ?
                    <DailySummary /> :
                    <WeeklySummary />
                }
            </div>
        </div>
    )
}
