import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import moment from 'moment'
import ExpandableView from '../../../Reports/Bets/ExpandableView';
import { APP_OFFSET } from '../../../../../constant';
import Spinner from '../../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../../Utils/sportsbook';

const columns = [
    {
        name: 'Participant',
        selector: row => row.participant,
        sortable: true,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking.toFixed(2),
        sortable: true
    },
    {
        name: 'To Win',
        selector: row => row.winnings.toFixed(2),
        sortable: true
    },
    {
        name: 'Bet Type',
        selector: row => row.bet_type,
        sortable: true
    },
    {
        name: 'Placed Date',
        selector: row => row.final_date,
        sortable: true,
        minWidth: "230px"
    }
];

export default function Unsettled({ data }) {
    const [preparedData, setPreparedData] = useState(null)
    const prepareData = async () => {
        if (data) {
            for (let row of data) {
                row.participant = getBetslipTitle(row);
                row.final_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"
            }
            setPreparedData(data)
        }
    }

    useEffect(() => {
        prepareData();
    }, [data])

    return (
        <>
            {preparedData ?
                <DataTable
                    columns={columns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView isModal={true} />}
                    expandableRowExpanded={() => preparedData.length === 1}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={undefined}
                /> :
                <Spinner />}
        </>
    )
}
