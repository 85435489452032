import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import {getImg} from "../Utils/BetData"

import moment from 'moment';
import momentTZ from "moment-timezone";

import {PROGRESS_STATUS, SCHEDULED_STATUS } from '../../../../../../../constant';

const MlbTable = ({item}) => {
  const [awayTeamScore, setAwayTeamScore] = useState([]);
  const [homeTeamScore, setHomeTeamScore] = useState([]);
  const [homeTotalScore, setHomeTotalScore] = useState(0);
  const [awayTotalScore, setAwayTotalScore] = useState(0);
  const [isMatchSchedule,setIsMatchSchedule]=useState(false);

  useEffect(() => {

    if (!SCHEDULED_STATUS.includes(item?.event_status)) {

      const home_score_array = [...(item?.event_score?.score_home_by_period || [])];

      while (home_score_array.length < 9) {
        home_score_array.push("-");
      }

      const away_score_array = [...(item?.event_score?.score_away_by_period || [])];
      while (away_score_array.length < 9) {
        away_score_array.push("-");
      }

      setHomeTeamScore(home_score_array);
      setAwayTeamScore(away_score_array);
      const homeScoreSum = home_score_array.reduce((sum, score) => {
        return sum + (typeof score === 'number' ? score : 0);
      }, 0);

      const awayScoreSum = away_score_array.reduce((sum, score) => {
        return sum + (typeof score === 'number' ? score : 0);
      }, 0);
      setAwayTotalScore(awayScoreSum);
      setHomeTotalScore(homeScoreSum);


    }
    else{
        setIsMatchSchedule(true);
    }


  }, [])

  const dateGridSize = SCHEDULED_STATUS.includes(item?.event_status) ? 12 : 3;

  const formattedDate = `${moment
    .utc(item?.event_date)
    .utcOffset(moment().utcOffset())
    .format("ddd, MMM D, h:mm A")} ${momentTZ.tz(moment.tz.guess()).zoneAbbr()}`;

  return (
    <div className='odd-border mt-1'>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom className='bg-vb-dark-light p-2 ps-4' >
              {item.sport_name} / <span className='leage-name'>{item.league_name}</span>
            </Typography>
          </Grid>
        </Grid>
        <div className='p-3'>

          <Grid container spacing={2} >
            <Grid item xs={dateGridSize} sm={dateGridSize} md={dateGridSize} lg={SCHEDULED_STATUS.includes(item?.event_status) ? 12 : 4} xl={dateGridSize} >
              {SCHEDULED_STATUS.includes(item?.event_status) ? <div className='event-date ps-2'>{formattedDate}</div> : <div className='text-primary' >Live | {PROGRESS_STATUS.includes(item?.event_status) && 
              <span id={`status${item.id}`}  >
                {item.event_status_detail}
              </span>}</div>}
            </Grid>
            {!SCHEDULED_STATUS.includes(item?.event_status) && <>
            {[...Array(9)].map((_,index)=>(
               <Grid item xs={0.9} sm={0.9} md={0.8} lg={0.8} xl={0.8} key={index}>
               {index+1} 
             </Grid>

            ))}
            
            <Grid item xs={0.9} sm={0.9} md={0.8} lg={0.8} xl={0.8} className='leage-name'>
              R
            </Grid>
            </>
            }
            

          </Grid>
          <hr />
          <Grid container spacing={2}  >
            <Grid item xs={isMatchSchedule?12:3} sm={isMatchSchedule?12:3} md={isMatchSchedule?12:4} lg={isMatchSchedule?12:4} xl={isMatchSchedule?12:4}>
              <span className='me-3'>{getImg(item.sport_name)}</span>
              <span >{item?.home_team_name}</span>

            </Grid>
            {
              homeTeamScore.length ? <>{homeTeamScore.map((score, index) => (
                <Grid item xs={0.9} sm={0.9} md={0.8} lg={0.8} xl={0.8} key={index} >
                  <span className={index === 9 ? "leage-name" : null}>{score}</span>
                </Grid>


              )

              )}
                <Grid item xs={0.9} sm={0.9} md={0.8} lg={0.8} xl={0.8}  >
                  <span className="leage-name">{homeTotalScore}</span>
                </Grid>
              </> : null}

          </Grid>
          <hr />
          <Grid container spacing={2}   >
            <Grid item xs={isMatchSchedule?12:3} sm={isMatchSchedule?12:3} md={isMatchSchedule?12:4} lg={isMatchSchedule?12:4} xl={isMatchSchedule?12:4}>
            <span className='me-3'>{getImg(item.sport_name)}</span>
              <span >{item?.away_team_name}</span>
            </Grid>
            {
              awayTeamScore.length ? <>{awayTeamScore.map((score, index) => (
                <Grid item xs={0.9} sm={0.9} md={0.8} lg={0.8} xl={0.8} key={index} >
                  <span className={index === 9 ? "leage-name" : null}>{score}</span>
                </Grid>


              )

              )}
                <Grid item xs={0.9} sm={0.9} md={0.8} lg={0.8} xl={0.8}  >
                  <span className="leage-name">{awayTotalScore}</span>
                </Grid>
              </> : null}
          </Grid>
        </div>


      </div>
  )
}

export default MlbTable