import React, { useContext, useState } from 'react'
import user4 from "../../../../../Static/UserManagement/images/common/default-user-avtar.png";
import { COIN_TRANSFER_URL, getValuebet, postValuebet, SEARCH_USER_URL, SEND_COIN_TRANSFER_OTP } from '../../../../../API-Handle/services/auth_curd';
import { errorNotification, successNotification } from '../../../../../Components/CustComp/PPHAlert';
import { BASEURL } from '../../../../../API-Handle/config';
import DelayedTimer from '../../../../../Components/CustComp/DelayedTimer';
import { LockOutlined } from '@mui/icons-material';
import ValuebetContext from '../../../../../context/Valuebet/ValuebetContext';

export default function TransferCoin() {
    const [result, setResult] = useState(null)
    const [transferableCoin, setTransferableCoin] = useState('')
    const [uuid, setUuid] = useState('')
    const [otp, setOtp] = useState("");
    const [isLoanding, setIsLoading] = useState(false);
    const [isTransfering, setIsTransfering] = useState(false);

    const { userDetails, getUserInfo } = useContext(ValuebetContext);

    const handleSendOtp = async () => {
        setIsLoading(true)
        let res = await postValuebet(SEND_COIN_TRANSFER_OTP);
        if (res){
            successNotification(res.message)
            setTimeout(() => {
                setIsLoading(false);
            }, 60000);
        }
    };

    const transfer = async (e) => {
        e.preventDefault();
        if (otp === '' || otp.length !== 6) {
            errorNotification("Please enter valid OTP!");
            return
        }
        if (transferableCoin === '' || parseFloat(transferableCoin) <= 0) {
            errorNotification("Please enter valid coin amount!");
            return
        }

        let data = { to: result.id, coins: transferableCoin, otp: otp }

        setIsTransfering(true)
        let res = await postValuebet(COIN_TRANSFER_URL, data)
        if (res) {
            setUuid("")
            setResult(null)
            setOtp("")
            setTransferableCoin("")
            successNotification(res.message)
            getUserInfo()
        }
        setIsTransfering(false)
    }

    const fetchUser = async () => {
        if (uuid === '') return
        setIsLoading(true)
        let res = await getValuebet(`${SEARCH_USER_URL}?uuid=${uuid}`)
        if (res) {
            setResult(res)
            setUuid('')
        }
        setIsLoading(false)
    }

    return (
        <div>
            <div className='mb-2 text-white'>
                <label className='d-flex'>Search & Transfer Coins: {!userDetails?.level_details?.p2p_transfer && (
                    <div className="d-flex align-items-center ms-1">
                        <LockOutlined fontSize="small" className="icon-bet-no" />
                        <div className="text-warning font-size-10px mb-1 ms-1">Preview</div>
                    </div>
                    )}
                </label>
                <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                        <span className="input-group-text bg-dark text-white rounded-end-0" id="basic-addon1">@</span>
                    </div>
                    <input type="text" className="form-control bg-dark text-white" value={uuid} onChange={(e) => setUuid(e.target.value)} placeholder="Recipient's uuid" aria-label="Recipient's uuid" aria-describedby="basic-addon1" />
                    <div className="input-group-append">
                        <button disabled={isLoanding} type="button" className="btn btn-outline-danger rounded-start-0" onClick={fetchUser}>Search</button>
                    </div>
                </div>
            </div>
            {!(result) &&
                <div className="alert alert-info" role="alert">
                    Please type exact uuid and search to transfer coins to another user!
                </div>}

            {result &&
                <>
                    <ul className="list-group">
                        <li className="list-group-item d-flex justify-content-start lh-condensed bg-dark">
                            <img alt='Avtar' src={result.profile_picture !== null && result.profile_picture !== "" && result.profile_picture !== 'null' ? BASEURL + result.profile_picture : user4} className="rounded-circle thumb-md" />
                            <div className='ms-3 mt-1'>
                                <h6 className="my-0 text-white">{result.username}</h6>
                                <small className="text-light">{result.email}</small>
                            </div>
                        </li>
                    </ul>

                    <form onSubmit={transfer}>
                        <div className="my-2">
                            <div className="d-flex align-items-center border rounded p-2">
                                <input
                                    type="number"
                                    className="form-control bg-dark text-white font-size-13px"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e)=> setOtp(e.target.value)}
                                    required={true}
                                />
                                <div className="border-start mx-3" style={{ height: "30px" }}></div>
                                <button 
                                    type="button" 
                                    onClick={handleSendOtp} 
                                    className="btn btn-outline-secondary text-nowrap font-size-12px" 
                                    disabled={isLoanding}
                                >
                                        {isLoanding ? <>Resend in <DelayedTimer timer={60} /></>  : "Send OTP"}
                                </button>
                            </div>
                        </div>
                        <div className="card bg-dark">
                            <div className="input-group input-group-sm">
                                <input type="number" value={transferableCoin} onChange={(e) => setTransferableCoin(e.target.value)} className="form-control bg-dark text-white" placeholder="Coin amount" required={true} />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-success rounded-start-0" disabled={isTransfering}>Transfer</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </>}
        </div>
    )
}
