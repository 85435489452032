import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  CardActionArea,
  Avatar,
  Button,
  TextField,
  InputLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ChatIcon from "@mui/icons-material/Chat";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { styles } from "../../Css/styles";
import MarketList from "../Common/MarketList";
import SkeletonCard from "../../../../Components/CustLoader/Cardskeleton";
import CloseIcon from "@mui/icons-material/Close";
import SliderButtons from "./SlugTag";
import SearchBar from "./Searchbar";
import { formatToMillions, formatValue, probabilityToAmericanOdds } from "../../../../Utils/valuemakets";
import { useNavigate } from "react-router";
import HalfCircleProgress from "./CircleProgress";
import Gauge from "./CircleProgress";
import MarketTable from "./MarketTable";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { BASEURL } from "../../../../API-Handle/config";
import { useDispatch, useSelector } from "react-redux";
import { mobileViewBtn } from "../../../../redux/features/polyMarketSlice";
import { getEventsList } from "../../../../redux/services/valuebetmarketServices";

export default function Index({mobView}) {
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  const scrollContainerRef = useRef(null); // Create a ref for custom scroll container
  const [slug, setSlug] = useState("");
  const  [order, setOrder] = useState("volume24hr")
  const [search, setSearch] = useState("");
  const [view, setView] = useState("grid");
  const [offset, setOffset] = useState(0);
const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const polybetDetails = useSelector(
    (state) => state.polyMarket.polybetDetails
  );
  const mobileView = useSelector(
      (state) => state.polyMarket.mobileView
    );


    const gdfg = useSelector(
      (state) => state.polyMarket
    );

    const getSlugData = useSelector(
      (state) => state.polyMarket.slugData.data
    );

    const firstSection = useSelector(
      (state) => state.polyMarket.firstSection
    );

    const events = useSelector(
      (state) => state.polyMarket.eventsData
    );

    const toggleFilter = useSelector(
        (state) => state.polyMarket.toggleFilter
      );

  const { bet_info, bets } = polybetDetails;

  const handleSort = (value) => {
    console.log(value)
    setOrder(value)
  }

  const baseParams = useMemo(
    () => ({
      limit: 20,
      active: true,
      archived: false,
      closed: false,
      order: order,
      ascending: false,
      offset: 0,
    }),
    []
  );
  
  const getParams = () => {
    const params = { ...baseParams };
  
    if (slug && slug !== 'Reset') {
      params.tag_slug = slug;
    }
  
    if (order) {
      params.order = order;
    }
  
    if (search) {
      return { _c: slug, _q: search, _p: 1 };
    }
  
    return params;
  };
  
  
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const params = getParams();
        console.log(params)
        await dispatch(getEventsList(params));
      } catch (error) {
        console.error("Error fetching initial events:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (loading || order) {
      fetchInitialData();
    }
  }, [slug, order, search, loading, getSlugData]); // Ensure all dependencies are listed
  


const loadMoreData = async () => {
  if (loadingMore) return; // Prevent duplicate calls
  setLoadingMore(true);

  try {
    const params = { limit: 20, offset: offset + 20 }; // Increment offset
    await dispatch(getEventsList(params, true)); // Fetch and append data
    setOffset((prevOffset) => prevOffset + 20); // Update offset
  } catch (error) {
    console.error("Error fetching more events:", error);
  } finally {
    setLoadingMore(false);
  }
};

useEffect(() => {
  const handleScroll = () => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;

    // Check if the user has scrolled near the bottom
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      loadMoreData();
    }
  };

  const container = scrollContainerRef.current;
  container.addEventListener("scroll", handleScroll); // Add scroll event to the container
  return () => container.removeEventListener("scroll", handleScroll); // Cleanup on unmount
}, [offset, loadingMore, dispatch]);


  const handleSlugChange = (slug) => {
    setSlug(slug);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const valueType = (item) => {
    setSelectedOption((prev) => (prev === item ? null : item));
  };

  const handleNavigate = (id) => {
  navigate(`/value-markets/${id}`)
  }


  return (
    <Grid
      container
      spacing={2}
      mt="71px"
      mr={2}
      ml={0}
      sx={{
        maxWidth: {
          xs: "100%",
          sm: "100%",
          lg: toggleFilter ? "60%" : "70%",
          md: toggleFilter ? "60%" : "70%",
          xl: toggleFilter ? "60%" : "70%",
        },
        display: {
          xs: mobView  ? 'block' : 'none',
          sm: mobView ? 'block' : 'none',
          lg: "block",
          md: "block",
          xl: "block",
        },
        transition: "max-width 1s ease, visibility 0.1ms ease, opacity 1s ease-out",
      }}
      
    >
      <SearchBar
        view={view}
        handleSort={handleSort}
        order={order}
        handleSearch={handleSearch}
        handleViewChange={handleViewChange}
      />
       
      <SliderButtons
        data={getSlugData}
        slug={slug}
        handleSlugChange={handleSlugChange}
      />
      <Grid
        container
        spacing={1}
        my={1}
        ref={scrollContainerRef} // Attach the ref to the custom scroll container

        sx={{
          ml: {
            xs: 0,
            sm: 0,
            lg: 1,
            md: 1,
            xl: 2,
          },
          maxHeight: "calc(100vh - 200px)",
          overflowY: "auto",
          pr: 1,
          width:{
            xs:'100%'
          }
        }}
      >
        {loading
          ? Array.from({ length: 12 }).map((_, index) => (
              <SkeletonCard toggleFilter={toggleFilter} key={index} />
            ))
          : events?.map((item) => {
            // Check if the market length is 1 and the outcomePrices contain only 0 or 1
            if (
              item?.markets?.length === 1 &&
    item?.markets.every((row) => {
      const prices = row.outcomePrices ? JSON.parse(row.outcomePrices) : [];
      return !prices.some((price) => {
        const probability = parseFloat(price);
        const americanOdds = probabilityToAmericanOdds(probability);
        if (americanOdds <= 0.0001) {
          return false;
        }
        return americanOdds !== 0 || americanOdds !== 1;
      });
    })
            ) 
            {
              return null; // Skip rendering this card
            }
          
            return (
              <Grid
                item
                lg={toggleFilter ? 6 : 4}
                xl={6}
                md={6}
                sm={6}
                xs={12}
                mb={{ xs: 0, lg: 0, md: 0 }}
                key={item.id}
                sx={styles.box}
              >
                <Card
                  sx={{
                    ...styles.cardStyle,
                    "&:hover": {
                      span: {
                        color: "#d52b00",
                      },
                    },
                  }}
                >
                  <CardContent sx={{ p: 2 }}>
                    { (
                      <Box sx={styles.top}>
                        <Avatar
                          onClick={() => setSelectedOption(item.id)}
                          src={item.image}
                          sx={styles.image}
                          style={{ cursor: "pointer" }}
                        />
                        <Typography
                          sx={styles.title}
                          variant="heading6"
                          onClick={() => handleNavigate(item.id)}
                        >
                          {item.title}
                        </Typography>
                      </Box>
                    )}
          
                    <Box>
                      <Box
                        sx={{
                          ...styles.scrollbar,
                          mt:item?.markets?.length === 1 ? "58px" : "20px",
                          maxHeight: item?.markets?.length <= 1 ? "100%" : "100px",
                        }}
                      >
                        {[...item.markets] // Shallow copy to avoid immutability issues
                          .sort((a, b) => {
                            const pricesA = a.outcomePrices
                              ? JSON.parse(a.outcomePrices)
                              : [];
                            const pricesB = b.outcomePrices
                              ? JSON.parse(b.outcomePrices)
                              : [];
                            const maxPriceA =
                              pricesA.length > 0
                                ? Math.max(...pricesA.map(Number))
                                : 0;
                            const maxPriceB =
                              pricesB.length > 0
                                ? Math.max(...pricesB.map(Number))
                                : 0;
                            return maxPriceA - maxPriceB;
                          })
                          .filter((row) => {
                            const prices = row.outcomePrices
                              ? JSON.parse(row.outcomePrices)
                              : [];
                            return prices.some((price) => {
                              const probability = parseFloat(price);
                              const americanOdds = probabilityToAmericanOdds(
                                probability
                              );
                              if (americanOdds <= 0.0001) {
                                return false;
                              }
                              return americanOdds !== 0 || americanOdds !== 1;
                            });
                          })
                          .map((row, index) => (
                            <MarketList
                              key={index}
                              singleMarket={{
                                isSingle: item?.markets?.length <= 1,
                                bestBid: row.bestBid,
                                image: item.image,
                                title: item.title,
                              }}
                              marketId={row.id}
                              image={row.image}
                              eventId={item.id}
                              eventName={item.title}
                              name={row.groupItemTitle}
                              outcome={row.outcomes}
                              token={row.clobTokenIds}
                              price={row.outcomePrices}
                              valueType={valueType}
                              uiType={"INSIDE"}
                            />
                          ))}
                      </Box>
                    </Box>
                  </CardContent>
                  <Box sx={styles.footPart}>
                    <CardActionArea sx={styles.footArea}>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 0.5,
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "12px", fontFamily: "inherit" }}
                        >
                          $ {formatToMillions(item.volume)} VoL
                        </Typography>
                      </Box>
                      <Box sx={styles.iconRyt}>
                        <StarBorderIcon fontSize="small" />
                      </Box>
                    </CardActionArea>
                  </Box>
                </Card>
              </Grid>
            );
          })
          }
          {loadingMore && <Typography sx={{py:2,position:'sticky',bottom:0,width:'100%',textAlign:'center',fontSize:'12px'}}>Loading more...</Typography>}
      </Grid>
      

    </Grid>
  );
}