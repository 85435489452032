import React, { useContext, useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { getSportLimits } from '../../../../Utils/sportsbook';
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';

export default function MoreDetailsButton({ league_name, item, setIsClickedMoreDetails, setCuurentBetMoreDetails, currentBetMoreDetails }) {
  const [isPropsActive, setIsPropsActive] = useState(false);
  const { betDetails, sports } = useContext(ValuebetContext);


  useEffect(()=>{
    let limits = getSportLimits(sports, league_name, 'bet_limits');
    setIsPropsActive(limits.is_props_activate);
  },[sports, league_name])

  const handleClickMoreDetails = () => {
    setIsClickedMoreDetails(true);
    setCuurentBetMoreDetails({ ...currentBetMoreDetails, ...item });
  }

  return (
    isPropsActive && betDetails?.bet_info?.bet_type !== "Teaser" && ["MLB", "NFL", "NCAAF","NHL","NBA"].includes(league_name) ?
    <div className="vb-text-orange font-size-15px d-flex align-items-center" role="button" onClick={handleClickMoreDetails}>
      Props + <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </div>:
    <></>
  )
}
