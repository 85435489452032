import { createSlice } from "@reduxjs/toolkit";

const valuebetSlice = createSlice({
  name: "valuebet",
  initialState: {
    activeClub: null,
    primaryClubItem:null,
  },
  reducers: {
    setActiveClub(state, actions) {
      state.activeClub = actions.payload.data;
    },
    setPrimaryClubItem(state, actions){
      state.primaryClubItem=actions.payload.data;
    }

  },
});

export const valuebetAction = valuebetSlice.actions;
export default valuebetSlice.reducer;
