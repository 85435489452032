import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const dummyData = [
  { name: "Jan", price: 100 },
  { name: "Feb", price: 120 },
  { name: "Mar", price: 90 },
  { name: "Apr", price: 140 },
  { name: "May", price: 150 },
  { name: "Jun", price: 130 },
  { name: "Jul", price: 170 },
  { name: "Aug", price: 160 },
  { name: "Sep", price: 180 },
  { name: "Oct", price: 200 },
  { name: "Nov", price: 210 },
  { name: "Dec", price: 190 },
];

const MarketGraph = () => {
  return (
    <ResponsiveContainer width="100%" height={150}>
      <LineChart data={dummyData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default MarketGraph;
