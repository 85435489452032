import React, { useState } from "react";
import { Select, MenuItem, ListItemIcon, ListItemText, FormControl } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import OpacityIcon from "@mui/icons-material/Opacity";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HistoryIcon from "@mui/icons-material/History";
import SportsIcon from "@mui/icons-material/Sports";
import { Padding } from "@mui/icons-material";

const CustomDropdown = ({handleSort,order}) => {
  const [dropdownValue, setDropdownValue] = useState("Trending");

  const styles = {
    selectBox: {
      width: "auto",
      display:'flex',
      backgroundColor: '#222',
    color: '#fff',
    alignItems: 'center',
      color: "#fff",
      ".MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#222",
      },
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
      '& div':{
        display:'flex',
        px:0.5,
        py:0.2
      }
    },
    menu: {
      backgroundColor: "#0b0b0b",
      color: "#fff",
      display:'flex',
      mt:1,
    },
    menuItem: {
        display:'flex',
        fontFamily:'inherit',
        fontSize:'12px',
        'svg':{
          position:'relative',
          top:'4px'
        },

      "&:hover": {
        backgroundColor: "#222",
      },
    },
  };

  return (
    <FormControl>
      <Select
        value={order}
        onChange={(e) => handleSort(e.target.value)}
        variant="outlined"
        sx={styles.selectBox}
        MenuProps={{
          PaperProps: {
            sx: styles.menu,
          },
        }}
      >
        <MenuItem value="volume24hr" sx={styles.menuItem}>
          <ListItemIcon>
            <TrendingUpIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Trending" />
        </MenuItem>
        <MenuItem value="liquidity" sx={styles.menuItem}>
          <ListItemIcon>
            <OpacityIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Liquidity" />
        </MenuItem>
        <MenuItem value="volume" sx={styles.menuItem}>
          <ListItemIcon>
            <EqualizerIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Volume" />
        </MenuItem>
        <MenuItem value="startDate" sx={styles.menuItem}>
          <ListItemIcon>
            <NewReleasesIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Newest" />
        </MenuItem>
        <MenuItem value="endDate" sx={styles.menuItem}>
          <ListItemIcon>
            <HourglassBottomIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Ending soon" />
        </MenuItem>
        <MenuItem value="closedTime" sx={styles.menuItem}>
          <ListItemIcon>
            <HistoryIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Ended recently" />
        </MenuItem>
        <MenuItem value="competitive" sx={styles.menuItem}>
          <ListItemIcon>
            <SportsIcon sx={{ color: "#fff" }} />
          </ListItemIcon>
          <ListItemText primary="Competitive" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
