import React, { useEffect, useState } from 'react'

const DelayedTimer = ({ timer }) => {
  const [count, setCount] = useState(timer);

  useEffect(() => {
    setCount(timer);
  }, [timer])

  useEffect(()=>{
    if (count >= 0){
      setTimeout(() => {
        setCount((prev) => prev - 1);
      }, 1000)
    }
  }, [count])

  return (
    <>
      {count}
    </>
  )
}

export default DelayedTimer