import React, { useContext, useState, useEffect } from 'react';
import { Check } from '@mui/icons-material';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { UPDATE_CLUB_URL, postValuebet } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification, errorNotification } from '../../../../../../Components/CustComp/PPHAlert';
import { refinHeading } from '../../../../../../Utils/comman';

export default function UpdateName({ clubInfo }) {
    const { fetchSingleClub } = useContext(ValuebetContext);
    const [clubName, setClubName] = useState('');

    const handleChange = (e) => {
        setClubName(refinHeading(e.target.value))
    }

    const renameClub = async () => {
        if (!clubName) {
            errorNotification("Please enter valid Club Name.");
            return;
        } else if (!clubName.length) {
            errorNotification("Please enter valid Club Name.");
            return;
        } else if (clubName.length > 20) {
            errorNotification("Club name has max 20 characters limit.");
            return;
        }
        let data = { "name": clubName };
        let res = await postValuebet(`${UPDATE_CLUB_URL + clubInfo.id}`, data);
        if (res) {
            successNotification(res.message);
            fetchSingleClub(clubInfo.id);
        }
    }

    useEffect(() => {
        setClubName(clubInfo.name)
    }, [clubInfo])

    return (
        <>
            <label className='text-white pb-2'>Update Club Name:</label>
            <div className='d-flex'>
                <div className='me-2 flex-grow-1'>
                    <input type="text" placeholder='Club name' className="form-control form-control-sm mb-1 bg-dark text-white border-secondary" value={clubName} onChange={(e) => handleChange(e)} />
                </div>
                <div>
                    <button onClick={renameClub} type="button" className="btn btn-danger btn-orange btn btn-sm"> <Check fontSize='mediam' /> </button>
                </div>
            </div>
        </>
    )
}
