import {
  getValuebet,
  POLYMARKETS_FILTERS_LISTS,
  POLYMARKETS_LISTS,
  POLYMARKETS_SLUG_LISTS,
} from "../../API-Handle/services/auth_curd";
import {
  setEventsData,
  setFiltersData,
  setSlugData,
} from "../features/polyMarketSlice";

export const getAllMarketSlugList = (param) => {
  return async (dispatch) => {
    let URL = `${POLYMARKETS_SLUG_LISTS}${param}`;
    let res = await getValuebet(URL);
    dispatch(setSlugData({ data: res }));
  };
};



export const getEventsList = (params, append = false) => {
    return async (dispatch) => {
        try {
            const queryString = new URLSearchParams(params).toString(); // Convert params to query string
            const URL = `${POLYMARKETS_LISTS}?${queryString}`; // Append query string to the URL
            const res = await getValuebet(URL); // Fetch data
            dispatch(setEventsData({ data: res, append })); // Dispatch with `append` flag
        } catch (error) {
            console.error("Error fetching events:", error);
        }
    };
};



export const getAllMarketFiltersList = () => {
  return async (dispatch) => {
    let URL = `${POLYMARKETS_FILTERS_LISTS}`;
    let res = await getValuebet(URL);
    dispatch(setFiltersData({ data: res }));
  };
};
