import {configureStore} from '@reduxjs/toolkit';
import clubchatSlice from '../features/clubchatSlice';
import sportsbookSlice from '../features/sportsbookSlice';
import polyMarketSlice from '../features/polyMarketSlice'
import valuebetSlice from '../features/valuebetSlice';

export const store = configureStore({
    reducer: {
     clubChat: clubchatSlice,
     sportBook:sportsbookSlice,
     polyMarket:polyMarketSlice,
     valuebet:valuebetSlice
    },
  });