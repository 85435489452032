import React, { useContext, useEffect, useState } from "react";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { Grid, Typography, Button } from "@mui/material";
import { getAllMarketFiltersList, getAllMarketSlugList } from "../../../../redux/services/valuebetmarketServices";
import { useDispatch, useSelector } from "react-redux";
import { setIsCalling } from "../../../../redux/features/polyMarketSlice";

export default function Sidebar({mobView}) {
  const dispatch = useDispatch();
  const [slug,setSlug] = useState('all');

  const toggleFilter = useSelector(
    (state) => state.polyMarket.toggleFilter
  );

  const filtersData = useSelector(
    (state) => state.polyMarket.filtersData.data
  );

  const firstSection = useSelector(
        (state) => state.polyMarket.firstSection
      );

  const getSlugByFilterSlug = (slug) => {
    setSlug(slug)
    dispatch(setIsCalling(true))
  };

  useEffect(()=>{
    dispatch(getAllMarketSlugList(`?tag=${slug}&status=active`))
  },[slug])

  useEffect(()=>{
    dispatch(getAllMarketFiltersList());
  },[])

  return (
    <Grid
      item
      sx={{
        p: 2,
        maxWidth: {
          xl:toggleFilter ? "30%" : "0",
           md:toggleFilter ? "30%" : "0",
            lg:toggleFilter ? "30%" : "0",
           sm:toggleFilter ? "100%" : "100%",
           xs:toggleFilter ? "100%" : "100%"

        },
        transition: "max-width 1s , visibility 0.1ms ease, opacity 1s ease-in",
        overflow: "hidden", 
        width: {
          xl:toggleFilter ? "auto" : "0",
          md:toggleFilter ? "auto" : "0",
           lg:toggleFilter ? "auto" : "0",
          sm:toggleFilter ? "100%":"100%",
          xs:toggleFilter ? "100%":"100%"
        },
        display: {
          xl:toggleFilter ? "block" : "none",
           md:toggleFilter ? "block" : "none",
            lg:toggleFilter ? "block" : "none",
            xs: mobView ? 'block' : 'none',
            sm: mobView ? 'block' : 'none',
        }
      }}
      mt="71px"
    >
      {Array.isArray(filtersData) && filtersData.length > 0 ? (
        filtersData.map((item, index) => (
          <Button
            onClick={() => getSlugByFilterSlug(item.slug)}
            sx={{
              background: "transparent",
              justifyContent: "start",
              display: "flex",
              alignItems: "start",
              color: item.slug === slug ? '#d52b00' : "white",

              "&:hover": {
                background: "transparent",
                color: "red",
              },
            }}
            key={index}
          >
            <Typography
              variant="body"
              sx={{
                fontSize: "14px",
                textAlign: "start",
                whiteSpace: "nowrap",
                fontFamily: "inherit",
                textTransform: "capitalize",
              }}
            >
              {item.label}
            </Typography>
          </Button>
        ))
      ) : (
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontSize: "14px",
            fontFamily: "inherit",
          }}
        >
          No filters available
        </Typography>
      )}
    </Grid>
  );
}