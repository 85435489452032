import React, { useEffect, useState } from "react";
import { Box, Drawer } from "@mui/material";
import ChatDrawer from "./ChatDrawer";
import { useSelector, useDispatch } from "react-redux";
import { clubchatAction } from "../../../redux/features/clubchatSlice";
import { fetchAllClubMember, fetchClubChannel } from "../../../redux/services/chatServices";
import { CHANNELTYPE } from "../../../constant";

const drawerWidth = 300;
function Index() {
  const dispatch = useDispatch();
  const {
    selectedClub,
    mobileOpen,
    isPulicChannel,
    isClubAdmin,
  } = useSelector((state) => state.clubChat);
  const [innerWidth, setInnnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (selectedClub) {
      if (isClubAdmin) {
        dispatch(fetchAllClubMember(selectedClub?.id));
      }
      if (isPulicChannel) {
        dispatch(fetchClubChannel(selectedClub.id, CHANNELTYPE.group));
      } else {
        dispatch(fetchClubChannel(selectedClub?.id, CHANNELTYPE.personal));
      }
    }
    // eslint-disable-next-line
  }, [selectedClub]);

  const handleDrawerClose = () => {
    dispatch(clubchatAction.openDrawer({ data: true }));
    dispatch(clubchatAction.openMobile({ data: false }));
  };

  const handleDrawerTransitionEnd = () => {
    dispatch(clubchatAction.openDrawer({ data: false }));
  };

  useEffect(() => {
    getInnerWidth()
    window.addEventListener('resize', getInnerWidth);
    return () => window.removeEventListener('resize', getInnerWidth)
  }, [])

  const getInnerWidth = () => {
    setInnnerWidth(window.innerWidth)
  }

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {innerWidth < 600 ?
          <Drawer
            container={document.body}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            className="bg-vb-dark"
          >
            <ChatDrawer />
          </Drawer> :
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            className="bg-vb-dark"
            open
          >
            <ChatDrawer />
          </Drawer>}
      </Box>
    </>
  );
}

export default Index;
