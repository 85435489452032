import React, { useEffect, useRef } from "react";
import { setSelectedMember, createChannel } from "../../../../../redux/services/chatServices";
import { useDispatch, useSelector } from "react-redux";
import { CHANNELTYPE } from "../../../../../constant";
import { useLocation } from "react-router";
import FindMembers from "../../../comman/FindMembers";

const SearchMembers = () => {
  const { search } = useLocation();
  const { isClubAdmin, selectedClub, allPersonalChat } = useSelector((state) => state.clubChat);
  const allPersonalChatRef = useRef([]);
  const dispatch = useDispatch();

  const onSelectMember = (item) => {
    if (item !== null) {
      setChatActive(item.id);
    }
  }

  const setChatActive = (member_id, force_create = true) => {
    let allMembers = [];
    let activeChannel = null;

    allPersonalChat?.forEach((pch) => {
      allMembers.push(pch?.detail?.id)
      if (member_id === pch?.detail?.id) {
        activeChannel = pch;
      }
    });
    let is_exists = allMembers.some((it) => it === member_id);

    if (is_exists) {
      dispatch(setSelectedMember(activeChannel, activeChannel.id));
    } else if (force_create) {
      dispatch(createChannel(selectedClub.id, null, [member_id], CHANNELTYPE.personal, selectedClub));
    }
  };

  const onSelectByMemberSection = (force_create) => {
    const searchParams = new URLSearchParams(search);
    const redirect_type = searchParams.get("type");
    const member_id = searchParams.get("member_id");
    if (redirect_type === 'MEM_CHAT') setChatActive(member_id, force_create);
  }

  useEffect(() => {
    if (allPersonalChatRef.current?.length !== allPersonalChat?.length) {
      onSelectByMemberSection(false);
    }
    return () => {
      allPersonalChatRef.current = allPersonalChat
    }
    // eslint-disable-next-line
  }, [allPersonalChat])

  useEffect(() => {
    onSelectByMemberSection(true);
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {(isClubAdmin || selectedClub?.is_special) && (
        <div className="py-2">
          <FindMembers onChange={onSelectMember} exincludes={[{id:selectedClub?.user_id}]} />
        </div>
      )}
    </>
  );
};

export default SearchMembers;
