import React, { useEffect, useState } from 'react'
import Winners from './Winners';
import { GET_CLUB_LEADERBOARD, getValuebet } from '../../../../../API-Handle/services/auth_curd';

export default function Leaderboard({ club }) {
    const [activeTab, setActiveTab] = useState(null);
    // To avoid showing loader multipule time using below status 
    const [dailyLeaderboard, setDailyLeaderboard] = useState(null);
    const [weeklyLeaderboard, setWeeklyLeaderboard] = useState(null);
    const [monthlyLeaderboard, setMonthlyLeaderboard] = useState(null);

    const fetchWinners = async () => {
        let res = await getValuebet(`${GET_CLUB_LEADERBOARD + club.id}?report_type=${activeTab}`)
        if (res) {
            if (activeTab === 'Daily') setDailyLeaderboard(res)
            if (activeTab === 'Weekly') setWeeklyLeaderboard(res)
            if (activeTab === 'Monthly') setMonthlyLeaderboard(res)
        }
    }

    useEffect(() => {
        if (activeTab) fetchWinners();
        // eslint-disable-next-line
    }, [activeTab])

    useEffect(() => {
        let active_tab = club.leaderboard_status.daily ? "Daily" : club.leaderboard_status.weekly ? "Weekly" : club.leaderboard_status.monthly ? "Monthly" : null;
        setActiveTab(active_tab);
        // eslint-disable-next-line
    }, [club])

    return (
        <>
            <div className="card text-start bg-vb-dark mb-0">
                <div className="card-header seperater">
                    <ul className="nav nav-tabs card-header-tabs">
                        {club.leaderboard_status.daily && <li className="nav-item">
                            <span className={`${activeTab === 'Daily' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"}`} role='button' onClick={() => setActiveTab('Daily')}>Daily</span>
                        </li>}
                        {club.leaderboard_status.weekly && <li className="nav-item">
                            <span className={`${activeTab === 'Weekly' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"}`} role='button' onClick={() => setActiveTab('Weekly')}>Weekly</span>
                        </li>}
                        {club.leaderboard_status.monthly && <li className="nav-item">
                            <span className={`${activeTab === 'Monthly' ? "nav-link custom-active active bg-vb-dark font-size-12px" : "nav-link custom-deactive text-white font-size-12px"}`} role='button' onClick={() => setActiveTab('Monthly')}>Monthly</span>
                        </li>}
                    </ul>
                </div>
                {activeTab === 'Daily' ?
                    <div className="card-body px-3 py-2 mt-1">
                        <Winners club={club} data={dailyLeaderboard} />
                    </div>
                    : activeTab === 'Weekly' ?
                        <div className="card-body px-3 py-2 mt-1">
                            <Winners club={club} data={weeklyLeaderboard} />
                        </div>
                        : activeTab === 'Monthly' ?
                            <div className="card-body px-3 py-2 mt-1">
                                <Winners club={club} data={monthlyLeaderboard} />
                            </div>
                            : null}
            </div>
        </>
    )
}