import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { getValuebet, GET_ALL_PLAYER_SUMMARY } from '../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CustomWeekPicker from '../../../../Components/Calendar/CustomWeekPicker';
import Spinner from '../../../../Components/CustLoader/Spinner';

const columns = [
    {
        name: 'Player',
        selector: row => row.username,
        sortable: true,
        minWidth: "135px"
    },
    {
        name: 'Beginning Of Week',
        selector: row => row.starting_balance,
        sortable: true,
    },
    {
        name: "Mon",
        selector: row => row.Mon,
        sortable: true,
    },
    {
        name: "Tue",
        selector: row => row.Tue,
        sortable: true,
    },
    {
        name: "Wed",
        selector: row => row.Wed,
        sortable: true,
    },
    {
        name: "Thu",
        selector: row => row.Thu,
        sortable: true,
    },
    {
        name: "Fri",
        selector: row => row.Fri,
        sortable: true,
    },
    {
        name: "Sat",
        selector: row => row.Sat,
        sortable: true,
    },
    {
        name: "Sun",
        selector: row => row.Sun,
        sortable: true,
    },
    {
        name: 'Weekly Player Results',
        selector: row => row.win_loss,
        sortable: true,
        minWidth: "125px"
    },
    {
        name: 'Chips In/Out',
        selector: row => row.in_out,
        sortable: true,
    },
    {
        name: 'Unsettled',
        selector: row => row.unsettled,
        sortable: true,
    },
    {
        name: 'Player Chips',
        selector: row => row.balance,
        sortable: true,
    }
];

export default function WeeklySummary() {
    const [isLoading, setIsLoading] = useState(false);
    const [week, setWeek] = useState(null);
    const [total, setTotal] = useState({});
    const [summary, setSummary] = useState([]);
    const [allSummary, setAllSummary] = useState([]);
    const [isActiveOnly, setIsActiveOnly] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (week) getPlayersSummary()
        // eslint-disable-next-line
    }, [week])

    useEffect(() => {
        const activePlayer = allSummary.filter(item => {
            return item.Mon > 0 || item.Tue > 0 || item.Wed > 0 || item.Thu > 0 || item.Fri > 0 || item.Sat > 0 || item.Sun > 0;
        });
        if (isActiveOnly) {
            setSummary(activePlayer)
        }
        else {
            setSummary(allSummary)
        }
    }, [isActiveOnly, allSummary])

    const getPlayersSummary = async () => {
        let club_id = location?.state?.club?.id;
        if (!club_id) return;

        setIsLoading(true);
        let res = await getValuebet(`${GET_ALL_PLAYER_SUMMARY + club_id}?report_time=${week}&report_type=Weekly`)
        if (res) {
            res.forEach((item) => {
                item['in_out'] = item['in_out'].toFixed(2);
                item['win_loss'] = item['win_loss'].toFixed(2);
                item['unsettled'] = item['unsettled'].toFixed(2)
                item['balance'] = item['balance'].toFixed(2)

            })
            setAllSummary(res);
            let total_in_out = 0;
            let total_mon = 0;
            let total_tue = 0;
            let total_wed = 0;
            let total_thu = 0;
            let total_fri = 0;
            let total_sat = 0;
            let total_sun = 0;
            let total_win_loss = 0;
            let total_unsettled = 0;

            for (let hist of res) {
                total_in_out += Number(hist['in_out']);
                total_mon += Number(hist['Mon']);
                total_tue += Number(hist['Tue']);
                total_wed += Number(hist['Wed']);
                total_thu += Number(hist['Thu']);
                total_fri += Number(hist['Fri']);
                total_sat += Number(hist['Sat']);
                total_sun += Number(hist['Sun']);
                total_win_loss += Number(hist['win_loss']);
                total_unsettled += Number(hist['unsettled']);
            }

            let total_obj = {
                username: "Total",
                starting_balance: "-",
                Mon: total_mon.toFixed(2),
                Tue: total_tue.toFixed(2),
                Wed: total_wed.toFixed(2),
                Thu: total_thu.toFixed(2),
                Fri: total_fri.toFixed(2),
                Sat: total_sat.toFixed(2),
                Sun: total_sun.toFixed(2),
                win_loss: total_win_loss.toFixed(2),
                in_out: total_in_out.toFixed(2),
                unsettled: total_unsettled.toFixed(2),
                balance: "-",
            }
            setTotal(total_obj)
        }
        setIsLoading(false);
    }

    const playerReport = async (row) => {
        navigate(
            "/players-report",
            { state: { member: { id: row.user, username: row.username }, club: location?.state?.club } }
        )
    }

    return (
        <div>
            <div className='inline-datepicker-container'>
                <CustomWeekPicker setWeek={setWeek} className={"settled-datepicker"} position={"bottom right"} />
            </div>
            <div className='float-end' >
                <label className='text-light'>
                    Active Players Only
                    <input
                        type="checkbox"
                        checked={isActiveOnly}
                        onChange={(e) => setIsActiveOnly(e.target.checked)}
                    />
                </label>
            </div>
            {!isLoading ?
                <div className="no-tbl-header">
                    <DataTable
                        columns={columns}
                        data={summary}
                        theme="dark"
                        dense={true}
                        header={false}
                        pointerOnHover={true}
                        highlightOnHover={true}
                        onRowClicked={playerReport}
                        footer={total}
                    />
                </div> :
                <Spinner />}
        </div>
    )
}
