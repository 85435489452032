// redux/slices/betSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  polybetDetails: {
    bet_info: {
      bet_type: "Straight",
      risking: 100,
      to_win: 1000,
    },
    bets: [],
  },
  mobileView:false,
  slugData:[],
  filtersData:[],
  eventsData:[],
  toggleFilter:false,
  firstSection:false,
  mobileScreen:{
    step1:true,
    step2:false,
    step3:false
 },
 isCalling:false
};

const betSlice = createSlice({
  name: 'polymarket',
  initialState,
  reducers: {

    setMobileScreen: (state, action) => {
      // Reset all steps to false
      Object.keys(state.mobileScreen).forEach((key) => {
        state.mobileScreen[key] = false;
      });
    
      // Set the step from the payload to true
      state.mobileScreen[action.payload] = true;
    },
    addBet: (state, action) => {
      state.polybetDetails.bets.push(action.payload); // Push the new bet into the bets array.
    },
    updateBets: (state, action) => {
      // Replace the bets array immutably
      state.polybetDetails.bets = action.payload.map(bet => ({ ...bet }));
  },
    removeBet: (state, action) => {
        const { odd_id } = action.payload;
        state.polybetDetails.bets = state.polybetDetails.bets.filter(
          (bet) => bet.odd_id !== odd_id
        ); // Remove the bet with the specified odd_id.
      },
    mobileViewBtn: (state, action) => {
        state.mobileView = action.payload; // Replace bets with updated array.
    },
    updateBetInfo: (state, action) => {
        const { odd_id, risking, to_win } = action.payload;
        
        // Find the bet by `odd_id` and update its properties
        const betIndex = state.polybetDetails.bets.findIndex(bet => bet.odd_id === odd_id);
        if (betIndex !== -1) {
          state.polybetDetails.bets[betIndex].risking = risking;
          state.polybetDetails.bets[betIndex].to_win = to_win;
        }
      },
      updateBetType: (state, action) => {
        state.polybetDetails.bet_info.bet_type = action.payload;
      },
      clearBets: (state) => {
        state.polybetDetails.bets = []; // Clear all bets from the bets array.
      },
      setSlugData:(state,action) => {
        state.slugData = action.payload
      },
      setEventsData: (state, action) => {
        if (action.payload.append) {
            state.eventsData = [...state.eventsData, ...action.payload.data];
        } else {
            state.eventsData = action.payload.data;
        }
    },
    
    
      setFiltersData:(state,action) => {
        state.filtersData = action.payload
      },
      setFilterToggle:(state,action) => {
        state.toggleFilter = action.payload
      },
      setFirstSectionToggle:(state,action) => {
        state.firstSection = action.payload
      },
      setIsCalling:(state,action) => {
        state.isCalling = action.payload
      },
      
      
  },
});

export const {setSlugData,setIsCalling,setMobileScreen,setFirstSectionToggle,setEventsData,setFilterToggle,setFiltersData, addBet,updateBets,mobileViewBtn,removeBet,updateBetInfo,updateBetType,clearBets } = betSlice.actions;

export default betSlice.reducer;
