import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import Popup from "reactjs-popup";
import { postValuebet, SEND_VERIFY_EMAIL_OTP, VERIFY_USER_EMAIL } from '../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../Components/CustComp/PPHAlert';
import { manageAllInputsErrors } from '../../../Utils/comman';
import CustTooltip from '../../../Components/CustComp/CustTooltip';
import { Warning } from '@mui/icons-material';

export default class VerifyEmailPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input_errors: {
                otp: '',
            },
            otp: "",
            is_field_tracker_on: false,
            has_email_sent: false,
            is_loading: false,
        }
    }

    handleChange = (e) => {
        if (this.state.is_field_tracker_on) {
            const { otp_error } = manageAllInputsErrors(e);
            this.setState({ input_errors: { otp: otp_error } })
        }
        this.setState({ [e.target.name]: e.target.value })
    }

    async sendOtp() {
        this.setState({ is_loading: true });
        let res = await postValuebet(SEND_VERIFY_EMAIL_OTP);
        if (res) {
            successNotification(res.message);
            this.setState({ has_email_sent: true });
        }
        this.setState({ is_loading: false });
    }

    async verifyAndUpdateEmail() {
        this.setState({ is_loading: true });
        const { otp_error } = manageAllInputsErrors({target:{name:"otp", value:this.state.otp}});
        this.setState({ input_errors: { otp: otp_error } })
        if (otp_error === '') {
            let data = { otp: this.state.otp }
            let res = await postValuebet(VERIFY_USER_EMAIL, data);
            if (res) {
                document.getElementById('verify-email').click();
                successNotification(res.message);
                this.props.callback();
            }
            else {
                this.setState({ has_email_sent: false, otp: "" });
            }
        } else {
            this.setState({ is_field_tracker_on: true });
        }
        this.setState({ is_loading: false });
    }

    render() {
        return (
            <div>
                <div>
                    <Popup
                        trigger={
                            <button className='p-0 btn btn-sm' id='verify-email' type="button"> 
                                <CustTooltip
                                    id="email-verification"
                                    icon= {<Warning style={{color: "yellow", fontSize:20, marginTop:-5}} />}
                                    text={"Unverified"}
                                />
                            </button>
                        } modal
                    >
                        {close => (
                            <div className='popup-container' >
                                <span className='clz-btn' onClick={close}> &times;</span>
                                <div className='p-3'>
                                    <form>
                                        <div className="field">
                                            <label className='text-warning' htmlFor="email"> Unverified Email - Verify Now </label>
                                            <input
                                                type="email"
                                                name="email"
                                                className='form-control form-control-sm bg-dark text-white border-secondary'
                                                onChange={this.handleChange}
                                                id="email"
                                                readOnly={true}
                                                value={this.props.email}
                                                required />
                                        </div>
                                        {this.state.has_email_sent && <>
                                            <div className="field mt-3">
                                                <label className='text-secondary' htmlFor="otp"> OTP </label>
                                                <input
                                                    type="number"
                                                    name="otp"
                                                    className='form-control form-control-sm bg-dark text-white border-secondary'
                                                    onChange={this.handleChange}
                                                    id="otp"
                                                    value={this.state.value}
                                                    required />
                                            </div>
                                            {this.state.input_errors.otp ? <div className='inputerrors'> {this.state.input_errors.otp} </div> : null}
                                        </>}
                                        <Row className="form-group mt-4">
                                            <Col md="12" className="text-center">
                                                {this.state.has_email_sent ?
                                                    <Button onClick={() => this.verifyAndUpdateEmail()}
                                                        className="btn btn-danger btn-sm btn-orange w-md" disabled={this.state.is_loading} type="button"> Verify Email
                                                    </Button> :
                                                     <div className='d-flex justify-content-around'>
                                                        <Button onClick={() => this.sendOtp()}
                                                            className="btn btn-danger btn-sm btn-orange w-md" disabled={this.state.is_loading} type="button"> {this.state.is_loading ? 'Sending...' : 'OK & Send OTP'}
                                                        </Button>
                                                        <Button onClick={() => close()}
                                                            className="btn btn-secondary btn-sm w-md" disabled={this.state.is_loading} type="button"> Cancel
                                                        </Button>
                                                     </div>
                                                    }
                                            </Col>
                                        </Row>
                                    </form>
                                </div>
                            </div>
                        )}
                    </Popup>
                </div>
            </div>
        )
    }
}
