import React from "react";
import PublicChatAction from "./PublicChatAction";
import { Collapse } from "reactstrap";
import { Toolbar } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useDispatch, useSelector } from "react-redux";
import { collapsePublicChannel } from "../../../../../redux/services/chatServices";
import NoData from "../DrawerCommon/NoData";
import AddChannel from "./AddChannel";
import PublicItem from "./PublicItem";

function PublicChannel() {
  const dispatch = useDispatch();
  const { selectedClub, isPulicChannel, clubGroup, isClubAdmin, isAddChannel } = useSelector((state) => state.clubChat);
  return (
    <>
      {isAddChannel && <AddChannel />}

      <div className="position-relative">
        <Toolbar>
          <div className="d-flex justify-content-between align-items-center w-100">
            <strong className="fw-bolder font-size-18px">
              {selectedClub?.is_special ? "P2P Chat" : selectedClub?.name}
            </strong>
            {(isClubAdmin || selectedClub?.is_special) && <PublicChatAction />}
          </div>
        </Toolbar>
        <div className="px-3 py-2">
          {/**Channel Message */}
          <div
            onClick={() => {
              dispatch(collapsePublicChannel(isPulicChannel, selectedClub.id));
            }}
            className="d-flex p-2"
          >
            {isPulicChannel ? (
              <KeyboardArrowDownIcon className="me-2" />
            ) : (
              <ChevronRightIcon className="me-2" />
            )}
            Public Channel
          </div>
          <Collapse isOpen={isPulicChannel}>
            <div className="gap-y-2 ps-4">
              {clubGroup && clubGroup?.length > 0 ? (
                clubGroup.map((item, index) => (
                  <PublicItem item={item} key={index} />
                ))
              ) : (
                <NoData msg={"No Channel Created Yet"} />
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
}

export default PublicChannel;
