import React, { useContext, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import ExpandableView from './ExpandableView';
import moment from 'moment'
import { PLAYER_BET_HISTORY } from '../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../API-Handle/services/auth_curd';
import { useLocation } from 'react-router-dom'
import { APP_OFFSET } from '../../../../constant';
import Spinner from '../../../../Components/CustLoader/Spinner';
import { getBetslipTitle } from '../../../../Utils/sportsbook';
import DeleteBet from './BetActions/DeleteBet';
import ValuebetContext from '../../../../context/Valuebet/ValuebetContext';


const columns = [
    {
        name: 'Participant',
        selector: row => row.participant,
        sortable: true,
        minWidth: "240px"
    },
    {
        name: 'Risk',
        selector: row => row.risking,
        sortable: true
    },
    {
        name: 'To Win',
        selector: row => row.winnings,
        sortable: true
    },
    {
        name: 'Bet Type',
        selector: row => row.bet_type,
        sortable: true
    },
    {
        name: 'Placed Date',
        selector: row => row.final_date,
        sortable: true,
        minWidth: "230px"
    }
];

export default function Unsettled() {
    const [preparedData, setPreparedData] = useState(null);
    const [dynamicColumns, setDynamicColumns] = useState(columns);
    const [unsettledTotal, setUnsettledTotal] = useState({});
    const location = useLocation()
    const { activeClub} = useContext(ValuebetContext); 

    const getBets = async () => {
        setPreparedData(null)
        let club_id = location?.state?.club?.id;
        if (!club_id) return;

        let player_id = location?.state?.member?.id
        let request_url = PLAYER_BET_HISTORY + club_id + `?bet_type=UNSETTLED`;
        if (player_id) {
            request_url += `&player_id=${player_id}`;
        }
        let bets = await getValuebet(request_url);
        prepareData(bets);

        let unsettled_total = {
            participant: `Total`,
            risking: 0,
            winnings: 0,
            bet_type: "-",
            final_date: "-",
            action: "-"
        }
        bets.forEach(bet => {
            unsettled_total.risking += bet.risking;
            unsettled_total.winnings += bet.winnings;
        })
        unsettled_total.risking = unsettled_total.risking.toFixed(2)
        unsettled_total.winnings = unsettled_total.winnings.toFixed(2)
        setUnsettledTotal(unsettled_total)
    }

    const prepareData = async (data) => {
        if (data) {
            for (let row of data) {
                row.participant = getBetslipTitle(row);
                row.final_date = moment.utc(row.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"
            }
            setPreparedData(data)
        }
    }

    useEffect(() => {
        if(["Owner", "Manager"].includes(activeClub?.label) && location?.state?.member?.id){
            let temp_col = [...dynamicColumns]
            temp_col[5] = {
                name: 'Action',
                cell: row => <DeleteBet data={row} getBets={getBets}/>,
                sortable: true,
                width: "100px"
            }
            setDynamicColumns(temp_col)
        }
        getBets()
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {preparedData ?
                <DataTable
                    columns={dynamicColumns}
                    data={preparedData}
                    expandableRows
                    expandableRowsComponent={<ExpandableView isModal={false} />}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                    footer={unsettledTotal}
                /> :
                <Spinner />}
        </>
    )
}
