import React, { useContext, useEffect, useState } from 'react'
import PublicHeader from '../../../Components/PublicHeader';
import UserHeader from '../../../Components/UserHeader';
import PublicFooter from '../../../Components/PublicFooter';
import { Col, Row } from 'reactstrap';
import { GET_CHIPS_PRICING_URL, GET_CLUB_LEVEL_PRICING_URL, GET_LEVEL_PRICING_URL, getValuebet } from '../../../API-Handle/services/auth_curd';
import COIN from '../../../Static/UserManagement/images/common/COIN.png';
// import TOKEN from '../../Static/UserManagement/images/common/TOKEN.png';
import ValuebetContext from '../../../context/Valuebet/ValuebetContext';
import BuyCoins from '../../../Components/Purchase/BuyCoins';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Prices() {
    const [clubLevels, setClubLevels] = useState(null);
    const [userLevels, setUserLevels] = useState(null);
    const [chipsPricing, setChipsPricing] = useState(null);
    const { userDetails } = useContext(ValuebetContext);

    const getChipsPricingList = async () => {
        let res = await getValuebet(GET_CHIPS_PRICING_URL);
        if (res) {
            setChipsPricing(res)
        }
    }

    const getUserLevelList = async () => {
        let res = await getValuebet(GET_LEVEL_PRICING_URL)
        if (res) {
            res.sort((a, b) => a.level - b.level)
            setUserLevels(res)
        }
    }
    
    const getClubLevelList = async () => {
        let res = await getValuebet(GET_CLUB_LEVEL_PRICING_URL)
        if (res) {
            res.sort((a, b) => a.level - b.level)
            setClubLevels(res)
        }
    }

    useEffect(() => {
        getChipsPricingList()
        getUserLevelList()
        getClubLevelList()
    }, [])

    const buyCoins = async () => {
        if (userDetails) {
            document.getElementById('buy-chips').click();
        } else {
            document.getElementById('login_1').click();
        }
    }

    return (
        <>
            <div id='public-page' className='BG-valuebet'>
                {localStorage.getItem("authToken") ?
                    <UserHeader /> :
                    <PublicHeader />}
                {userDetails && <BuyCoins showButton={false}/>}
                <div className='layout-mt-65'>
                    <div className="container text-center mb-5 page-main-heading">
                        <h1 className='reguler-heading'><span className='text_orange'>Valuebet.App </span> Price Schedule</h1>
                        <h5 className='reguler-heading'> <span className='text_orange'>Valuebet Coins</span> are needed to Buy Club Chips and Upgrade Clubs & Account Features</h5>
                    </div>
                    <div className="container text-center pb-3">
                        <Row>
                        <Col md={12} className='mb-3 text-start pricing-height'>
                                <label className='font-size-16px'>Chips Pricing</label>
                                <div className='border-bottom'></div>
                                <div className='overflow-x'>
                                    <table className="table text-center table-sm table-custom-dark price-tabel-fonts">
                                        <thead>
                                            <tr>
                                                <th scope="col">Chips</th>
                                                <th scope="col">Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody className="mx-2">
                                            {chipsPricing && chipsPricing.map((item) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td className='align-middle'>{item.chips} Chips</td>
                                                        <td className='align-middle'>{item.cost} Coins</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {!chipsPricing && <div className='pricing-height d-flex justify-content-center align-items-center'>
                                        <div className="spinner-grow text-success" role="status"></div>
                                        <div className="spinner-grow text-danger" role="status"></div>
                                        <div className="spinner-grow text-warning" role="status"></div>
                                    </div>}
                                </div>
                            </Col>
                            <Col md={12} className='mb-3 text-start'>
                                <label className='font-size-16px'>User Level Pricing</label>
                                <div className='border-bottom'></div>
                                <div className='overflow-x'>
                                    <table className="table text-center table-sm table-custom-dark price-tabel-fonts">
                                        <thead>
                                            <tr>
                                                <th scope="col">Level</th>
                                                <th scope="col">Max Clubs</th>
                                                <th scope="col">P2P Messaging</th>
                                                <th scope="col">P2P Coins Transfer</th>
                                                <th scope="col">Days</th>
                                                <th scope="col">Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody className="mx-2">
                                            {userLevels && userLevels.map((item) => {
                                                return (
                                                    <tr key={item.level}>
                                                        <td className='align-middle'>{item.level}</td>
                                                        <td className='align-middle'>{item.max_clubs}</td>
                                                        <td className='align-middle'>{item.p2p_message ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.p2p_transfer ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.days}</td>
                                                        <td className='align-middle text-nowrap'>{item.cost} Coins</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {!userLevels && <div className='pricing-height d-flex justify-content-center align-items-center'>
                                        <div className="spinner-grow text-success" role="status"></div>
                                        <div className="spinner-grow text-danger" role="status"></div>
                                        <div className="spinner-grow text-warning" role="status"></div>
                                    </div>}
                                </div>
                            </Col>
                            <Col md={12} className='mb-3 text-start'>
                                <label className='font-size-16px'>Club Level Pricing</label>
                                <div className='border-bottom'></div>
                                <div className='overflow-x'>
                                    <table className="table text-center table-sm table-custom-dark price-tabel-fonts">
                                        <thead>
                                            <tr>
                                                <th scope="col">Level</th>
                                                <th scope="col">Max Members</th>
                                                <th scope="col">Max Agents</th>
                                                <th scope="col">Odds Selection</th>
                                                <th scope="col">Sport Limits</th>
                                                <th scope="col">Messaging</th>
                                                <th scope="col">Leaderboard</th>
                                                <th scope="col">Bet Delay</th>
                                                <th scope="col">Days</th>
                                                <th scope="col">Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody className="mx-2">
                                            {clubLevels && clubLevels.map((item) => {
                                                return (
                                                    <tr key={item.level}>
                                                        <td className='align-middle'>{item.level}</td>
                                                        <td className='align-middle'>{item.max_members}</td>
                                                        <td className='align-middle'>{item.max_agents}</td>
                                                        <td className='align-middle'>{item.odds_selection ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.sport_limits ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.messaging ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.leaderboard ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.bet_delay ? <CheckCircleOutlineIcon className='icon-bet-yes-sm' /> : <CancelIcon className='icon-bet-no-sm' />}</td>
                                                        <td className='align-middle'>{item.days}</td>
                                                        <td className='align-middle text-nowrap'>{item.cost} Coins</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                    {!clubLevels && <div className='pricing-height d-flex justify-content-center align-items-center'>
                                        <div className="spinner-grow text-success" role="status"></div>
                                        <div className="spinner-grow text-danger" role="status"></div>
                                        <div className="spinner-grow text-warning" role="status"></div>
                                    </div>}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="container text-center mt-5">
                        <Row>
                            <Col md={12} className='mb-5'>
                                <h2 className='reguler-heading'>Buy <span className='text_orange'>Valuebet Coins</span></h2>
                                <img src={COIN} className="img-fluid shadow-lg" alt="Affiliate Header" width="200" height="200" loading="lazy" />
                                <h6 className='reguler-heading'>100 Valuebet Coins = $1.00 USD</h6>
                                <div className='my-3'>
                                    <button className='btn btn-orange text-white position-relative' onClick={buyCoins}> BUY NOW {userDetails && <span className="btn-live bg-success border border-light"></span>}
                                    </button>
                                </div>
                                {/* <h4 className='reguler-heading'>**First-time Buyers receive <span className='text_orange'>500 in free bonus Coins</span> with purchase of 8000 coins**</h4> */}
                            </Col>
                            {/* <Col md={6}>
                                <h2 className='reguler-heading'>Unlimited Use <span className='text_orange'>Tokens</span></h2>
                                <img src={TOKEN} className="img-fluid shadow-lg" alt="Affiliate Header" width="200" height="200" loading="lazy" />
                                <div className='my-3'>
                                    <button className='btn btn-orange text-white'> BUY NOW</button>
                                </div>
                                <h4 className='reguler-heading'>
                                    ***Grants Unlimited Use when applied to a single Club*** <br/>
                                    **Peer-to-Peer transferable** <br/>
                                    **Never Expires**
                                </h4>
                            </Col> */}
                        </Row>
                    </div>
                    <PublicFooter />
                </div>
            </div>
        </>
    )
}