export const styles = {
  box: {
    overflowY: "auto",
  },
  cardStyle: {
    borderRadius: "8px",
    minHeight: "200px",
    border:'1px solid #222',
    background:
      "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
    maxHeight: "200px",
    position: "relative",
  },
  top: {
    display: "flex",
    gap: "0.5rem",
    alignItems: "start",
  },
  image: {
    objectFit: "cover",
    height: "38px",
    width: "38px",
    borderRadius: 0,
  },
  subImage: {
    objectFit: "cover",
    height: "28px",
    width: "28px",
    borderRadius: 0,
  },
  
  title: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    fontFamily: "Ubuntu",
    display: "-webkit-box",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  footPart: {
    position: "absolute",
    width: "100%",
    left: 0,
    bottom: 0,
    background: "#222",
    px: "1rem",
    py: "0.2rem",
    button: {
      display: "flex",
      gap: "1rem",
    },
  },
  scrollbar: {
    mt: 2,
    overflowY: "auto",
    maxHeight: "100px",
    "&::-webkit-scrollbar": {
      width: "0",
    },
  },
  buttonScroll: {
    display: "flex",
    overflowX: "scroll",
    gap: 1,
    maxWidth:'100%',
    whiteSpace: "nowrap",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { display: "none" },
  },
  searchBar: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#0b0b0b",
    borderRadius: "4px",
    padding: "4px 8px",
    width: "100%",
    mb: 2,
    gap: 1,
    color: "white",
  },
  selectBox: {
    color: "white",
    maxHeight:'46px',
    backgroundColor: "#2c2c2c",
    borderRadius: "4px",
    ".MuiOutlinedInput-notchedOutline": { border: "none" },
  },
  searchWrapper: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#000000",
    borderRadius: "4px",
    padding: "2px 8px",
    flex: 1,
  },
  footArea: { display: "flex", justifyContent: "space-between" },
  iconRyt: { display: "flex", gap: "1rem" },
  memberSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    mb: 1,
  },
  memberName: { fontSize: "12px", fontFamily: "inherit" },
  statusClr: {
    borderRadius: 1,
    fontSize: "12px",
    px: 1.5,
    py: 0.5,
    fontFamily: "inherit",
  },
  yesColor: {
    background: "#00ff0036",
    color: "#00ff00",
  },
  noColor: {
    background: "#e03f0036",
    color: "#e03f00",
  },
  toggler: {
    backgroundColor: "#2c2c2c",
    borderRadius: "4px",
    ".MuiToggleButton-root": {
      color: "white",
    },
  },
};
