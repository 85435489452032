import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Avatar } from "@mui/material";
import { styles } from "../../Css/styles";
import { useDispatch } from "react-redux";
import { addBet, removeBet } from "../../../../redux/features/polyMarketSlice";
import { useSelector } from "react-redux";
import { probabilityToAmericanOdds } from "../../../../Utils/valuemakets";
import Gauge from "../Markets/CircleProgress";

const MarketList = ({
  image,
  marketId,
  eventId,
  name,
  outcome,
  token,
  price,
  uiType,
  eventName,
  singleMarket
}) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const dispatch = useDispatch();
  const polybetDetails = useSelector(
    (state) => state.polyMarket.polybetDetails
  );

  const { bet_info, bets } = polybetDetails;

  let parsedOutcomes = [];
  let parsedOutcomePrices = [];
  let parsedOutcomeId = [];

  try {
    parsedOutcomes = JSON.parse(outcome) || [];
  } catch {
    console.error("Invalid JSON in 'outcome'");
  }

  try {
    parsedOutcomePrices = JSON.parse(price) || [];
  } catch {
    console.error("Invalid JSON in 'price'");
  }

  try {
    parsedOutcomeId = JSON.parse(token) || [];
  } catch {
    console.error("Invalid JSON in 'price'");
  }

  const combinedData = parsedOutcomes.map((outcome, index) => ({
    outcome,
    price: parsedOutcomePrices[index],
    token: parsedOutcomeId[index]
  }));

  const valueType = (item) => {
    const existingBet = bets.find((bet) => bet.odd_id === item.token);

    if (existingBet) {
      // If the token exists, remove the bet
      dispatch(removeBet({ odd_id: existingBet.odd_id }));
    } else {
      let odd_money = probabilityToAmericanOdds(item.price);
      let subtitle = `Moneyline ${odd_money < 0 ? "" : "+"} ${odd_money} for ${item.outcome} - ${item.name}`;
     
      const newBet = {
        event_id: item.eventId,
        market_id: item.marketId || "",
        odd_id: item.token || "",
        avg_price: odd_money || "",
        avg_price_actual:item.price,
        outcome: item.outcome,
        eventName: item.eventName,
        sub_title:subtitle,
        init_sub_title:subtitle,
        marketName: item.name,
      };
      dispatch(addBet(newBet));
    }
  };


  return (
    <Box sx={{...styles.memberSection,mb:uiType === "OUTSIDE" ? 0 :1,flexDirection:!singleMarket.isSingle ? 'row' : 'column'}}>
      <Box sx={{ ...styles.top, alignItems: "center" }}>
        {uiType === "OUTSIDE" && (
          <Avatar src={image} sx={styles.image} style={{ cursor: "pointer" }} />
        )}
        <Typography sx={{...styles.memberName,fontSize:uiType === "OUTSIDE" ? '16px' : '12px'}}>{name}</Typography>
      </Box>
     
      <Box sx={{ display: "flex", gap: 1 }}>
        {combinedData.map((data, index) => {
          let odd_money = probabilityToAmericanOdds(data.price);
          const isSelected = bets.some((x) => x.odd_id === data.token);
          return (
            <Box
              key={index}
              sx={{ display: "flex", gap: 1, alignItems: "center" }}
            >
              {!singleMarket.isSingle && data.outcome === "Yes" && uiType !== "OUTSIDE" && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontFamily: "inherit",
                    fontWeight: 500,
                  }}
                >
                 
                 {odd_money < 0 ? "" : "+"}{odd_money}
                </Typography>
              )}

              <Button
                onMouseEnter={() => setHoverIndex(index)}
                onClick={() =>
                  valueType({ ...data, marketId, eventId, name, eventName })
                }
                onMouseLeave={() => setHoverIndex(null)}
                sx={{
                  ...styles.statusClr,
                  color:
                    isSelected || hoverIndex === index || data.outcome === "Yes"
                      ? "#fff"
                      : data.outcome === "No"
                      ? "#e64800"
                      : "#27ae60",
                  backgroundColor: isSelected
                    ? data.outcome === "Yes"
                      ? "#27ae60"
                      : "#e64800"
                    : hoverIndex === index || data.outcome === "Yes"
                    ? "#27ae601a"
                    : data.outcome === "No"
                    ? "#eb57571a"
                    : "#27ae601a",
                  borderRadius: "4px",
                  padding: "2px",
                  fontSize: (uiType === "OUTSIDE" || singleMarket.isSingle) ? "14px" : "12px",
                  minWidth: (uiType === "OUTSIDE" || singleMarket.isSingle) ? "128px" : "37px",
                  minHeight:(uiType === "OUTSIDE" || singleMarket.isSingle) ? "50px" : "auto",
                  "&:hover": {
                    background: data.outcome === "Yes" ? "#27ae60" : "#e64800",
                    color: "#fff",
                  },
                }}
              >
                {isSelected
                  ? data.outcome
                  : hoverIndex === index
                  ? `${odd_money < 0 ? "" : "+"}${odd_money}`
                  : data.outcome}
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default MarketList;