import React from 'react'
import { Link } from "react-router-dom";
import logoag from "../../../../../Static/UserManagement/images/common/club_avtar.png";
import { BASEURL } from '../../../../../API-Handle/config';
import CopyText from '../../../../../Components/CustComp/CopyText';
import CustTooltip from '../../../../../Components/CustComp/CustTooltip';
export default function PrimaryClubInfo({ item }) {
    return (
        <div className='d-flex justify-content-start'>
            <div className='club-logo-setup'>
                {(item.label === "Owner") | (item.label === "Manager") ? (
                    <Link to="/upload-club-icon" state={{ club_data: item }}>
                        {item.club_icon ? (
                            <img className="club-icon" src={BASEURL + item.club_icon} alt='Club Icon '/>
                        ) : (
                            <img className="club-icon" src={logoag} alt='Club Icon' />
                        )}
                    </Link>
                ) : item.club_icon ? (
                    <img className="club-icon" src={BASEURL + item.club_icon} alt='Club Icon' />
                ) : (
                    <img className="club-icon" src={logoag} alt='Club Icon' />
                )}
            </div>
            <div className="club-owner-inner mt-1">
                {item.name?.toUpperCase()}
                <div className="font-size-14px mt-1">
                    {" "}User ID : <span className='text-success'>{item.user_id.substring(0, 8)}</span> <CopyText text={`${window.location.origin}?referral_id=${item.user_id}`} />
                </div>
                <div className="font-size-12px d-flex mt-1">
                    Daily Chips: <span className='vb-text-orange letter-space'> {item?.user_chips?.player_chips.toFixed()} ({item?.user_chips?.locked_chips.toFixed()}) </span>
                    <CustTooltip
                        id={item.id} 
                        text={"Users are given 10,000 free chips to make any bets they want. Results counts toward Leaderboard Standings. Chips reset every day at 12am PST."} 
                        customClass="d-flex align-items-center ms-2"
                    />
                </div>
            </div>
        </div>
    )
}
