import React, { useState, useEffect } from 'react'
import { Modal } from "reactstrap";
import ChildRequest from '../../../../../../../Components/WalletCommanTabs/ChildRequest';
import BuyChips from '../../BuyChips';
import ChipsHistory from '../../../../../../../Components/WalletCommanTabs/ChipsHistory';
import AllInclusive from '@mui/icons-material/AllInclusive';
import ChipMgt from './ChipMgt';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ClubWallet({ item }) {
    const { available_chips, locked_chips } = item.club_wallet;
    const [transferChipModal, setTransferChipModal] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const { search, pathname } = useLocation();
    const navigate = useNavigate();

    const toggleClubChipModal = () => {
        setTransferChipModal(!transferChipModal)
        if (transferChipModal) navigate(pathname)
    };

    const notificationHandler = () => {
        const searchParams = new URLSearchParams(search);
        const noti_type = searchParams.get("type");
        const club_id = searchParams.get("club_id");
        if(club_id === item.id){
            if(['CHIPS_REQUEST'].includes(noti_type)){
                setTransferChipModal(true)
                if(noti_type === 'CHIPS_REQUEST') setActiveTab(2);
            }
        }
    }

    useEffect(() => {
        notificationHandler()
        // eslint-disable-next-line
    }, [item, search])

    return (
        <>
            <Modal isOpen={transferChipModal} toggle={toggleClubChipModal} className="modal-dialog-centered modal-xl">
                <div className="modal-header bg-vb-dark">
                    <h5 className="modal-title my-0"> Transfer Chips from/to Club's Wallet</h5>
                    <BuyChips club={item} />
                    <button onClick={toggleClubChipModal} type="button" className="text-white btn-close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="card mb-0 text-start bg-vb-dark">
                    <div className="card-header seperater">
                        <ul className="nav nav-tabs card-header-tabs">
                            <li className="nav-item">
                                <span className={activeTab === 1 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(1)}>Chip Mgt</span>
                            </li>
                            {item.label === 'Owner' ?
                                <>
                                    <li className="nav-item">
                                        <span className={activeTab === 2 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(2)}>Chip Req</span>
                                    </li>
                                </> : null}
                            <li className="nav-item">
                                <span className={activeTab === 3 ? "nav-link custom-active active bg-vb-dark" : "nav-link custom-deactive text-white"} role='button' onClick={() => setActiveTab(3)}>History</span>
                            </li>
                        </ul>
                    </div>
                    {activeTab === 1 ?
                        <div className="card-body pt-2">
                            <ChipMgt club={item} toggleClubChipModal={toggleClubChipModal} />
                        </div>
                        : activeTab === 2 ?
                            <div className="card-body">
                                <ChildRequest club_id={item.id} />
                            </div>
                            : activeTab === 3 ?
                                <div className="card-body">
                                    <ChipsHistory club_id={item.id} wallet_type="CLUB" />
                                </div>
                                : null}
                </div>
            </Modal>

            <button onClick={() => toggleClubChipModal()} type="button" className='club-chip-button flex-grow-1'>
                <span className='font-size-12px'>{item.is_once_ltd_assigned ? <AllInclusive style={{ fontSize: 14 }} /> : Math.round(available_chips)} ({Math.round(locked_chips)}) </span> <br />
                <span className='font-size-10px'>Club Wallet </span> {item.has_chips_request && <span className="text-danger font-size-14px">●</span>}
            </button>
        </>
    )
}
