import React from 'react'
import Transactions from './Transactions';
import BuyCoins from '../../../../../Components/Purchase/BuyCoins';
import TransferCoin from './TransferCoin';

export default function Index({ userDetails }) {
    
    return (
        <>
            <div className='d-flex justify-content-between align-items-center text-white mb-3 mt-1'>
                <div>
                    <span>VB Coins - </span>
                    <span className='border border-dark rounded p-2 ms-2'>  {userDetails?.coins} </span>
                </div>
                <BuyCoins />
            </div>
            <div className='row'>
                <div className="col-md-5">
                    <TransferCoin />
                </div>
                <div className='col-md-7'>
                    <Transactions coin={userDetails?.coins} />
                </div>
            </div>
        </>
    )
}
