import React, { useEffect, useState } from 'react'
import LockOutlined from '@mui/icons-material/LockOutlined';
import { postValuebet, UPDATE_BET_DELAY_URL } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification } from '../../../../../../Components/CustComp/PPHAlert';
import Switch from "react-switch";
import { Check } from '@mui/icons-material';

export default function BetDelay({ clubInfo }) {
    const [delaySeconds, setDelaySeconds] = useState(0);
    const [delayToggle, setDelayToggle] = useState(false);

    const updateBetDelaySeconds = async (e) => {
        e.preventDefault();
        const data = { delay_seconds: delaySeconds }
        let res = await postValuebet(`${UPDATE_BET_DELAY_URL + clubInfo.id}`, data);
        if (res) {
            successNotification(res.message);
        }
    }

    const updateBetDelayToggle = async (e) => {
        setDelayToggle(e)
        const data = { delay_toggle: e }
        let res = await postValuebet(`${UPDATE_BET_DELAY_URL + clubInfo.id}`, data);
        if (res) {
            successNotification(res.message);
        }
    }

    useEffect(() => {
        setDelayToggle(clubInfo.bet_delay_toggle)
        setDelaySeconds(clubInfo.bet_delay_seconds)
    }, [clubInfo])

    return (
        <>
            <label className='text-white d-flex'>
                Delayed Bets:
                <Switch
                    onChange={(e) => updateBetDelayToggle(e)}
                    checked={delayToggle}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                    height={22}
                    width={55}
                    onColor="#008000"
                    onHandleColor="#1cca1c"
                    offColor="#ffac8b"
                    offHandleColor="#e03f00"
                    borderRadius={8}
                    uncheckedIcon={
                        <div className='d-flex justify-content-center align-items-center h-100 pe-1 text-dark font-size-12px'>
                            Off
                        </div>
                    }
                    checkedIcon={
                        <div className='d-flex justify-content-center align-items-center h-100 ps-1 text-white font-size-12px'>
                            On
                        </div>
                    }
                    className="react-switch ps-2"
                />
                {!clubInfo.level_details.bet_delay && (
                    <span className='d-flex align-items-center ps-1'>
                        <LockOutlined fontSize='small' className='icon-bet-no' />
                        <span className="text-warning font-size-10px mb-1">Preview</span> {/* // TODO: Temp Unlocked */}
                    </span>
                )}
            </label>
            <form className="d-flex align-items-center mt-3" onSubmit={updateBetDelaySeconds}>
                <div className="input-group input-group-sm pe-2">
                    <input
                        type="number"
                        placeholder='Delayed Seconds'
                        className="form-control form-control-sm bg-dark text-white border-secondary"
                        aria-describedby='delay-bet-sec'
                        value={delaySeconds}
                        onChange={(e) => setDelaySeconds(e.target.value)}
                        min={1}
                        required={true}
                        disabled={!delayToggle}
                    />
                    <span className="input-group-text bg-secondary text-white" id="delay-bet-sec">Seconds</span>
                </div>
                <button type="submit" disabled={!delayToggle} className="btn btn-danger btn-orange btn btn-sm"> <Check fontSize='mediam' /> </button>
            </form>
        </>
    )
}
