import { OFFLINE_ODD } from "../constant";

export const formatToMillions = (value) => {
  if (value >= 1e6) {
    return (value / 1e6).toFixed(0) + "M";
  }
  return value?.toString();
};

export const formatValue = (value) => {
  if (value >= 1000) {
    return value.toLocaleString();
  }
  return value.toString();
};

export const probabilityToAmericanOdds = (probability, percentage = false) => {
  if (!percentage) probability = Math.round(probability * 100);
  if (probability < 0 || probability > 100) {
    throw new Error("Probability must be between 0 and 100.");
  }

  let odds;
  if (probability === 0 || probability === 100 ) return OFFLINE_ODD
  if (probability < 50) {
    // Calculate positive odds
    odds = 100 / (probability / 100) - 100;
  } else if (probability > 50) {
    // Calculate negative odds
    odds = (probability / (1 - probability / 100)) * -1;
  }
  return Math.round(odds); // need to return integer only
};
