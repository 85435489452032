import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import OddList from '../../../../Components/SportComp/OddList';
import { APP_OFFSET } from '../../../../constant';
import { getBetslipSubTitle } from '../../../../Utils/sportsbook';
import PolyOddList from '../../../../Components/PolyMarketComp/PolyOddList';


function ExpandableView({ data, isModal }) {
  const location = useLocation()
  const [subTitle, setSubTitle] = useState('');

  const prepareData = () => {
    let sub_title = [];
    data.bets.forEach((bet_info) => {
      let {bet, match} = bet_info;
      let bet_name = getBetslipSubTitle(bet, match);
      sub_title.push(bet_name)
      bet.name = bet_name.split(" - ")[0];
    })
    setSubTitle(sub_title.join(", "))
  }

  useEffect(() => {
    prepareData()
    // eslint-disable-next-line
  }, [data])

  const getAvailableWidth = () => {
    let temp_width = 0;
    if (isModal) {
      temp_width = window.innerWidth < 1280 ? window.innerWidth - 50 : 1240;
    } else {
      temp_width = window.innerWidth < 1400 ? window.innerWidth - 70 : 1330;
    }
    return temp_width;
  }

  return (
    <div className='px-4 py-4 bet-history-container' style={{ width: getAvailableWidth() }}>
      <div className='bet-history-top-box mb-2'>
        <div className='d-flex align-items-center w-100'>
          <div className='d-flex align-items-start justify-content-between w-100'>
            <div className='bet-name-box justify-content-start pe-2 w-100'>
              <strong>{data.bet_type !== "Teaser" ? data.bet_type: `${data.teaser.points} Points ${data.teaser.is_super_teaser ? "Super" : ''} ${data.bet_type}`}</strong>
               <span className='bet-subtitle-his'>{subTitle}</span>
            </div>
            <strong className='text-nowrap text-success'>{data.multibet_odd_money ? data.multibet_odd_money > 0 ? `+ ${data.multibet_odd_money}` : data.multibet_odd_money : ''}</strong>
          </div>
        </div>
      </div>
      <div className='horizantal-line'></div>
            {data.bet_category === "VALUEMARKET" ? <PolyOddList data={data.bets}/> : <OddList data={data.bets} /> }
      <div className='value-box-history py-2'>
        <div className='bet-name-box'>
          <strong>{Number(data.risking).toFixed(2)} <small>Chips</small> </strong>
          <small className='bet-subtitle-text'>Total wager</small>
        </div>
        <div className='bet-name-box'>
          {data.status === 'PENDING' ?
            <>
              <strong className='bet-subtitle-amount text-warning'>{(Number(data.risking) + Number(data.winnings)).toFixed(2)} <small>Chips</small></strong>
              <small className='bet-subtitle-text'>Payout if win (pending)</small>
            </>
            : null}
          {data.status === 'WON' ?
            <>
              <strong className='bet-subtitle-amount'>{Number(data.result).toFixed(2)} <small>Chips</small></strong>
              <small className='bet-subtitle-text'>{location?.state?.member ? 'Player' : 'You'} won on valuebet</small>
            </>
            : null}
          {data.status === 'LOSE' ?
            <>
              <strong className='bet-subtitle-amount text-danger'>{-(Number(data.result)).toFixed(2)} <small>Chips</small></strong>
              <small className='bet-subtitle-text'>{location?.state?.member ? 'Player' : 'You'} lose on valuebet</small>
            </>
            : null}
          {data.status === 'REFUNDED' ?
            <>
              <strong className='bet-subtitle-amount'>{Number(data.risking).toFixed(2)} <small>Chips</small></strong>
              <small className='bet-subtitle-text'>Chips refunded on valuebet</small>
            </>
            : null}
            {data.status === 'DELETED' ?
            <>
              <strong className='bet-subtitle-amount text-warning'>{Number(data.result).toFixed(2)} <small>Chips</small></strong>
              <small className='bet-subtitle-text'>BET DELETED</small>
            </>
            : null}
        </div>
      </div>
      <div className='bet-history-top-box py-2'>
        <small className='bet-subtitle-text'>bet id: {data.id}</small>
        <small className='bet-subtitle-text'>placed: {moment.utc(data.created_at).utcOffset(APP_OFFSET).format('L LT') + " PST"}</small>
      </div>
    </div>
  )
}

export default ExpandableView