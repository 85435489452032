import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { LinkToPlayerReport } from '../../../Components/CustComp/LinkToPlayerReport';
import { GET_CLUB_LEADERBOARD, getValuebet } from '../../../API-Handle/services/auth_curd';
import Spinner from '../../../Components/CustLoader/Spinner';
import CustomMonthPicker from '../../../Components/Calendar/CustomMonthPicker';

export default function MonthlyWinners({ club }) {
    const [winners, setWinners] = useState(null)
    const [month, setMonth] = useState(null);
    const columns = [
        {
            name: 'Rank',
            selector: row => row.rank,
            minWidth:"55px"
        },
        {
            name: 'Name',
            selector: row => row.username,
            cell: row => (club.leaderboard_status.report_toggle || ["Owner", "Manager"].includes(club.label)) ? <LinkToPlayerReport row={row} /> :  <div className='text-nowrap'>{row.username}</div>,
            minWidth:"130px"
        },
        {
            name: 'Risk',
            selector: row => row.total_risking,
        },
        {
            name: 'To Win',
            selector: row => row.total_to_win,
        },
        {
            name: 'W/L',
            selector: row => <>{row.win_count}-{row.loss_count}</>,
        },
        {
            name: 'Results',
            selector: row => row.total_result,
        },
    ];

    const getMonthlyWinners = async () => {
        setWinners(null)
        let request_url = GET_CLUB_LEADERBOARD + club.id + `?report_type=Monthly&report_time=${month}`;
        let data = await getValuebet(request_url);
        const lead_data = data.report_data.map((item, index) => ({
            ...item,
            rank: index + 1,
            user: item.uid
        }));
        setWinners(lead_data);
    }

    useEffect(() => {
        if(month) getMonthlyWinners();
        // eslint-disable-next-line
    }, [month])

    return (
        <>
            <div className='inline-datepicker-container'>
                <CustomMonthPicker getMonth={setMonth} className={"settled-datepicker"} />
            </div>
            {winners ?
                <DataTable
                    columns={columns}
                    data={winners}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                /> :
                <Spinner />}
        </>
    )
}
