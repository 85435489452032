import React from 'react'
import PlayerWallet from './PlayerWallet'
// import AgentWallet from './AgentWallet'
import ClubWallet from './ClubWallet'

export default function ClubLabel({ item }) {
    return (
        <>
            <div className={`text-center position-relative`}>
                <div className="text-center d-flex justify-content-between gap-2">
                    <ClubWallet item={item}/>
                    {/* <AgentWallet item={item}/> */}
                    <PlayerWallet item={item}/>
                </div>
            </div>
        </>
    )
}
