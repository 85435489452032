import React from 'react'
import { useNavigate } from 'react-router-dom';
import PlayerWallet from './PlayerWallet';

export default function PClubWallets({ item }) {
    const navigate = useNavigate();

    const goToYourReport = () => {
        navigate(
            "/sportbook/reports",
            {state: { club: item, summary_active: true }}
        );
    }

    return (
        <>
            <div className="text-center mini-stat-label">
                <div className="text-center d-flex justify-content-around gap-2">
                    <PlayerWallet item={item} />
                    <button type="button" onClick={goToYourReport} className='club-chip-button flex-grow-1'>
                        <span className='font-size-12px'>Report </span>
                    </button>
                    {/* Already commented */}
                    {/* <button type="button" className='club-chip-button'>
                            Kickbacks: {item.members[0]?.more_info.player_kickbacks}%
                        </button> */}

                    {/* TODO: Need to uncomment below code when kickback feature will enable */}

                    {/* <span role={"button"} className='font-size-10px player-kickbacks-btn'>
                            <b> Kickbacks - {item.members[0]?.more_info.player_kickbacks}%</b>
                        </span> */}
                </div>
                
            </div>
        </>
    )
}
