import React from 'react' 
import moment from 'moment';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useNavigate } from 'react-router-dom'
import SmSpinner from '../../../../../Components/CustLoader/SmSpinner';

const getArrow = (current, previous) => {
    let upArrow = <ArrowUpwardIcon style={{ fontSize: 14, paddingBottom: 1, color: "green" }} />;
    let downArrow = <ArrowDownwardIcon style={{ fontSize: 14, color: "red" }} />;

    if (current === previous) {
        return " ";
    } else if ((current === null) && (previous !== null)) {
        return downArrow;
    } else if ((current !== null) && (previous === null)) {
        return upArrow;
    } else if (current > previous) {
        return downArrow;
    } else {
        return upArrow;
    }
}

export default function Winners({ club, data, type }) {
    const navigate = useNavigate();

    const playerReport = async (row) => {
        navigate(
            "/players-report",
            {state: { member: { id: row.user, username: row.username }, club: club }}
        )
    }

    return (
        <div className='winner-table'>
            {data ? data?.report?.length ?
                <>
                    <div className="d-flex justify-content-between">
                        <label className='bg-dark p-1 text-light rounded font-size-11px' > {type === "Daily" ? moment(data?.report_date, 'DD/MM/YYYY').format('dddd, MMM. DD, YYYY') : type === "Weekly" ? data?.report_date : type === "Monthly" ? moment(data?.report_date, 'MM/YYYY').format('MMMM, YYYY') : null }</label>    
                        <label className='p-1 text-light rounded border border-dark font-size-11px' > Total Players : {data?.report?.length}</label>
                    </div>
                    <div className="table-responsive text-start">
                        <table className="table table-custom-dark text-nowrap table-borderless mb-0">
                            <thead>
                                <tr className='font-size-11px'>
                                    <td className='py-0 ps-0 col-6'>Rank (Last Rank)</td>
                                    <td className='py-0 col-4'>Result (Prev)</td>
                                    <td className='p-0 col-2'>W-L</td>
                                </tr>
                                <tr>
                                    <td className='px-0 pb-1 pt-0'><div className='horizantal-line' /></td>
                                    <td className='px-0 pb-1 pt-0'><div className='horizantal-line' /></td>
                                    <td className='px-0 pb-1 pt-0'><div className='horizantal-line' /></td>
                                </tr>
                            </thead>
                        </table>
                        <div className="overflow-auto leaderboard-table-body">
                            <table className="table table-custom-dark text-nowrap table-borderless mb-0">
                                <tbody className='font-size-10px'>
                                    {data.report.map((item, index) => {
                                        return (
                                            <tr key={item.rank}>
                                                <td className='py-0 ps-0 col-6'><span role="button" onClick={() => playerReport(item)}>{item.rank}. {item.username?.toUpperCase()} ({getArrow(item.rank, item.prev_rank)}#{item.prev_rank ? item.prev_rank : "N/A"} )</span></td>
                                                {type === "Daily" ? 
                                                    <td className='py-0 col-4'><span className="letter-space"> <span className={item.result < 0 ? "vb-text-orange" : "text-success"}>{Math.round(item.result)}</span> <span className={item.prev_result < 0 ? "vb-text-orange" : "text-success"}>({Math.round(item.prev_result)})</span> </span></td>:
                                                    <td className='py-0 col-4'><span className="letter-space"> <span className={item.leaderboard_result < 0 ? "vb-text-orange" : "text-success"}>{Math.round(item.leaderboard_result)}</span> <span className={item.prev_result < 0 ? "vb-text-orange" : "text-success"}>({Math.round(item.prev_result)})</span> </span></td>
                                                }
                                                <td className='p-0 col-2'><span className="letter-space">{item.wl_count}</span></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </> :
                <div className='text-center text-light py-3 font-size-10px'>
                    No Report Found!
                </div> :
                <SmSpinner my={0} py={3} />}
        </div>
    )
}
