import React, { useState } from 'react'
import MemberUnsettled from './MemberUnsettled';
import MemberSettled from './MemberSettled';

export default function Index({ club, item }) {
    const [betType, setBetType] = useState("UNSETTLED");

    return (
        <div>
            <div className='inline-datepicker-container'>
                <div className="btn-group mt-3" role="group">
                    <button type="button" className={betType === 'UNSETTLED' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('UNSETTLED')}>Pending</button>
                    <button type="button" className={betType === 'SETTLED' ? "btn btn-danger btn-sm" : "btn btn-secondary btn-sm"} onClick={() => setBetType('SETTLED')}>Settled</button>
                </div>
            </div>
            <div className="pt-0 no-tbl-header">
                {betType === 'UNSETTLED' ?
                    <MemberUnsettled club={club} item={item} /> :
                    <MemberSettled club={club} item={item} />
                }
            </div>
        </div>
    )
}