import React from 'react'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessTime from '@mui/icons-material/AccessTime';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import moment from 'moment'
import { APP_OFFSET, RUNNING_STATUS } from '../../constant';
import { getReadablePeriod } from '../../Utils/sportsbook';

export default function PolyOddList({ data }) {
    return (
        data.map((item, indx) => {
            let short_description = item.bet.market_name;
            if (item.bet?.market_id) short_description = item.bet.short_description;
            let away_period_score = null;
            let home_period_score = null;
            if (RUNNING_STATUS.includes(item?.match?.event_status)) {
                const leagueName = item.match.league_name;
                const scoreAwayByPeriod = item.match.score_away_by_period;
                const scoreHomeByPeriod = item.match.score_home_by_period;

                if (item.bet.period_name === "period_first_half") {
                    if (leagueName === 'MLB' && scoreAwayByPeriod.length > 5) {
                        away_period_score = `1H ${scoreAwayByPeriod.slice(0, 5).reduce((a, b) => a + b, 0)}`;
                        home_period_score = `1H ${scoreHomeByPeriod.slice(0, 5).reduce((a, b) => a + b, 0)}`;
                    } else if (leagueName === 'NCAAM' && scoreAwayByPeriod.length > 1) {
                        away_period_score = `1H ${scoreAwayByPeriod[0] || 0}`;
                        home_period_score = `1H ${scoreHomeByPeriod[0] || 0}`;
                    } else if (scoreAwayByPeriod.length > 2) {
                        away_period_score = `1H ${scoreAwayByPeriod.slice(0, 2).reduce((a, b) => a + b, 0)}`;
                        home_period_score = `1H ${scoreHomeByPeriod.slice(0, 2).reduce((a, b) => a + b, 0)}`;
                    }
                } else if (item.bet.period_name === "period_second_half") {
                    if (leagueName === 'NCAAM') {
                        away_period_score = `2H ${scoreAwayByPeriod.slice(1, 2).reduce((a, b) => a + b, 0)}`;
                        home_period_score = `2H ${scoreHomeByPeriod.slice(1, 2).reduce((a, b) => a + b, 0)}`;
                    } else {
                        away_period_score = `2H ${scoreAwayByPeriod.slice(2, 4).reduce((a, b) => a + b, 0)}`;
                        home_period_score = `2H ${scoreHomeByPeriod.slice(2, 4).reduce((a, b) => a + b, 0)}`;
                    }
                } else if (item.bet.period_name === "period_first_period") {
                    if (scoreAwayByPeriod.length > 1) {
                        away_period_score = `1Q ${scoreAwayByPeriod[0] || 0}`;
                        home_period_score = `1Q ${scoreHomeByPeriod[0] || 0}`;
                    }
                }
            }
            return (
                <div key={indx}>
                    <div className='bet-options py-2'>
                        <div>
                            {item.bet.is_correct === "PENDING" && <AccessTime className='icon-bet-pending' />}
                            {item.bet.is_correct === "YES" && <CheckCircleOutlineIcon className='icon-bet-yes' />}
                            {item.bet.is_correct === "NO" && <CancelIcon className='icon-bet-no' />}
                            {item.bet.is_correct === "TIE" && <RemoveCircle className='icon-bet-tie' />}
                            {(indx + 1) !== data.length && <div className='bet-line' />}
                        </div>
                        <div className='bet-options-box'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <strong className='bet-option-title'>{short_description}</strong>
                                    <br/>
                                    <small className='bet-option-subtitle'>{item.bet.name}</small>
                                </div>
                                <div>
                                    {item.bet.selected_odd_money !== "0" && <strong className='bet-option-title'>{item.bet.selected_odd_money > 0 ? `+${item.bet.selected_odd_money}` : item.bet.selected_odd_money}</strong>}
                                </div>
                            </div>
                            
                            <label className='font-size-10px fw-bolder text-secondary mb-1 mt-3'>{item?.bet?.market_description}</label>
                            {(indx + 1) !== data.length && <div className='horizantal-line'></div>}
                        </div>
                    </div>
                </div>
            )
        })
    )
}
