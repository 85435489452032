import React from 'react';
import { MarketType1, MarketType2, MarketType3 } from './MarketType';
import { updatedParticipants } from '../Utils/BetData';


const renderComponent = (market, item) => {

  if (['moneyline', 'moneyline_in_play', 'winning_margin_overtime', 'touchdowns'].includes(market.name))
    return <MarketType1 data={market} item={item} />;

  else if (['handicap','handicap_in_play', 'totals','totals_in_play', 'winning_margin','winning_margin_in_play', 'pitcher_strikeouts','pitcher_strikeouts_in_play', 'team_totals','team_points','team_points_in_play','team_totals_in_play'].includes(market.name))
  return <MarketType2 data={market} item={item} />;

  else if (['home_runs', 'runs', 'singles', 'passing_touchdowns','passing_touchdowns_in_play', 'triples', 'doubles', 'hits', 'player_receptions','player_receptions_in_play', 'player_assists', 'passing_touchdowns', 'player_points', 'player_shots','shots','player_goals'].includes(market.name))
    return <MarketType3 data={market} item={item} />;

  else if (['player_receiving_yards','player_receiving_yards_in_play', 'rushing_yards','rushing_yards_in_play', 'passing_yards','passing_yards_in_play',].includes(market.name))
    return <MarketType3 data={updatedParticipants(market)} item={item} />

};


export default function MarketUI({ item, propsMarketData }) {
  return (
    <div className='mt-4'>
      {propsMarketData?.map((market) => (
        <div key={market.id}>
          {renderComponent(market, item)}
        </div>
      ))}
    </div>
  );
}
