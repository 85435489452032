import React, {useContext, useState } from 'react';
import MoreDetailsTab from './MoreDetailsTab';
import { useLocation } from 'react-router';
import { GET_PROPS_MARKET, getValuebet } from '../../../../../../API-Handle/services/auth_curd';
import ScoreTable from './ScoreTable';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';

const MoreBetDetails = ({currentBetMoreDetails }) => {
  const [loading, setLoading] = useState(false);
  const [propsMarketData, setPropsMarketData] = useState([]);
  const [prevSelectType,setPrevSelectType]=useState(null);
  const {manipulateOddValue}=useContext(ValuebetContext);
  const location = useLocation();
  let club_id = location?.state?.club?.id;

  const getPropsMarket = async (selectedType) => {
    if(selectedType!==prevSelectType)
    {
      setLoading(true);
      setPrevSelectType(selectedType)

    }

    let url = `${GET_PROPS_MARKET}/${currentBetMoreDetails.event_id}?club_id=${club_id}`;
    if (selectedType && selectedType !== "All") {
      url = `${url}&participant_type=${selectedType}`
    }
    if(currentBetMoreDetails?.event_id)
    {
      let res = await getValuebet(url);
      if(res){
        res.forEach(market=>{
          market?.participants.forEach(participant=>{
            participant?.lines.forEach(line=>{
              line.league_name = currentBetMoreDetails?.league_name;
              line=manipulateOddValue(line);
            })
          })
        })
        setPropsMarketData(res);
      }

    }
    setLoading(false);

  }

  return (

    <div >
      <ScoreTable item={currentBetMoreDetails} />
      <MoreDetailsTab loading={loading} item={currentBetMoreDetails} propsMarketData={propsMarketData} getPropsMarket={getPropsMarket}  />
    </div>
  );
};

export default MoreBetDetails;
