import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { postValuebet, REMOVE_CLUB_URL } from '../../../../../../API-Handle/services/auth_curd';
import { successNotification, errorNotification } from '../../../../../../Components/CustComp/PPHAlert';
import club_avtar from "../../../../../../Static/UserManagement/images/common/club_avtar.png";
import { BASEURL } from '../../../../../../API-Handle/config';
import ValuebetContext from '../../../../../../context/Valuebet/ValuebetContext';
import { DefaultConfirm } from '../../../../../../Components/SweetAlerts/DefaultConfirm';
import UpgradeLevel from './UpgradeLevel';
import LineSelector from './LineSelector';
import LeaderboardConf from './LeaderboardConf';
import BetDelay from './BetDelay';
import UpdateName from './UpdateName';


export default function General({ item, toggleClubSetting }) {
    const { fetchClub } = useContext(ValuebetContext)
    const navigate = useNavigate();

    const removeClub = async () => {
        let message = `<p class="font-size-14px text-danger">Everything will be deleted and not restored.</p> Do you want to delete this club?`
        let confirmation = await DefaultConfirm("Delete Club", message)
        if (confirmation) {
            let res = await postValuebet(`${REMOVE_CLUB_URL + item.id}`);
            if (res) {
                successNotification(res.message);
                toggleClubSetting();
                fetchClub();
            }
        }
    }

    return (
        <div className="tab-content">
            <div className="tab-pane fade active show" id="account-general">
                <div className="my-3 d-flex align-items-center">
                    <img
                        onClick={() => {
                            navigate(
                                `/upload-club-icon`,
                                { state: { club_data: item } }
                            );
                        }
                        }
                        src={item.club_icon ? BASEURL + item.club_icon : club_avtar}
                        alt="Club Avatar"
                        className="d-block ui-w-80 rounded-circle"
                        role='button'
                    />
                    <div className="media-body ms-4 align-self-center">
                        <UpgradeLevel clubInfo={item} />
                        {item.label === "Owner" ?
                            <button onClick={removeClub} type="button" className="btn btn-secondary btn-sm mb-1">Delete Club</button> :
                            null}
                    </div>
                </div>
                <hr className="border-dark m-0" />
                <div className='mt-3'>
                    <div>
                        <div className='d-flex align-items-center justify-content-between mb-2 flex-wrap gap-3'>
                            <div className='d-flex align-items-center'>
                                <span className="px-2 py-1 border text-white border-danger rounded">Level - {item.level_details.level}</span>
                                <div className='font-size-12px'>
                                    {item.club_status === 'ACTIVE' &&
                                        <div className='px-2'> {item.level_details.level === 1 ?
                                            <span className='text-light'>(Default Level)</span> :
                                            <span className='text-success'>({item.level_details.remaining_days} Remaining Days)</span>}
                                        </div>}
                                    {item.club_status === 'UNLIMITED' &&
                                        <div className='px-2 text-success'> (Unlimited Access) </div>}
                                    {item.club_status === 'DISABLED' &&
                                        <div role='button' className="text-danger border border-danger rounded px-2 py-1" onClick={() => errorNotification("Disabled due to Unassignment of Token. Please reassign a Token to club to reactivate.")}> DISABLED </div>}
                                </div>
                            </div>
                            <span className="px-2 py-1 border border-danger text-white rounded">Max Members - {item.member_counts.players + item.member_counts.agents + item.member_counts.managers}/{item.level_details.member}</span>
                            {/* <span className="px-2 py-1 border border-danger text-white">Max Agents - {item.member_counts.agents + item.member_counts.managers}/{item.level_details.agent}</span> */}
                        </div>

                        <div className="horizantal-line my-3" />
                        <div className='d-flex flex-wrap gap-3'>
                            <div className='flex-grow-1 border border-secondary rounded p-3'>
                                <UpdateName clubInfo={item} />
                            </div>
                            <div className='flex-grow-1 border border-secondary rounded p-3'>
                                <LineSelector clubInfo={item} />
                            </div>
                        </div>
                        <div className='d-flex align-items-start justify-content-between flex-wrap gap-3 mt-3'>
                            <div className='flex-grow-1 border border-secondary rounded p-3 mh-115px'>
                                <LeaderboardConf clubInfo={item} />
                            </div>
                            <div className='flex-grow-1 border border-secondary rounded p-3 mh-115px delay-box'>
                                <BetDelay clubInfo={item} />
                            </div>
                        </div>
                    </div>

                    <div className="text-end mt-3">
                        <button onClick={toggleClubSetting} type="button" className="btn btn-secondary btn-sm">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
