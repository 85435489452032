import React from 'react';
import { Link } from "react-router-dom";
import logoag from "../../../../../Static/UserManagement/images/common/club_avtar.png";
import { BASEURL } from '../../../../../API-Handle/config';
import CopyText from '../../../../../Components/CustComp/CopyText';
import { errorNotification } from '../../../../../Components/CustComp/PPHAlert';

const getLabelClass = (item) => {
    let typeCls = '';
    if (item.label === "Player") {
        typeCls = "ptyp-text"
    } else if (item.label === "Agent") {
        typeCls = "atyp-text"
    } else if (item.label === "Manager") {
        typeCls = "mtyp-text"
    } else if (item.label === "Owner") {
        typeCls = "otyp-text"
    }
    return typeCls;
  }

export default function ClubInfo({ item }) {
    return (
        <div className='d-flex justify-content-start'>
            <div className='club-logo-setup'>
                {(item.label === "Owner") | (item.label === "Manager") ? (
                    <Link to="/upload-club-icon" state={{ club_data: item }}>
                        {item.club_icon ? (
                            <img alt='club icon' className="club-icon" src={BASEURL + item.club_icon} />
                        ) : (
                            <img alt='club icon' className="club-icon" src={logoag} />
                        )}
                    </Link>
                ) : item.club_icon ? (
                    <img alt='club icon' className="club-icon" src={BASEURL + item.club_icon} />
                ) : (
                    <img alt='club icon' className="club-icon" src={logoag} />
                )}
            </div>
            <div className={`club-owner-inner mt-1`}>
                <span className={`badge rounded-pill border border-1 border-danger ${getLabelClass(item)}`}>{item.label}</span>
                <div className={`font-size-12px mt-2`}>
                    {" "}Club ID : {item.id.substring(0, 8)} <CopyText text={item.id} />
                </div>
                {item.level_details.level &&
                    <div className='font-size-11px fw-normal mt-1'>
                        {item.club_status === 'ACTIVE' &&
                            <span> {item.level_details.level === 1 ?
                                <span className='text-light'> Level - {item.level_details.level} | Default Level </span> :
                                <span 
                                    className={`${item?.level_details?.is_extended ? "text-danger" : "text-success"}`}
                                > 
                                    Level - {item.level_details.level} | {item.level_details.remaining_days} Remaining Days
                                </span>}
                            </span>
                        }
                        {item.club_status === 'UNLIMITED' && <span className='text-success'> Level - {item.level_details.level} | Unlimited Access </span>}
                        {item.club_status === 'DISABLED' && <div role='button' className='text-danger border border-danger rounded px-2 mt-2 d-table' onClick={() => errorNotification("Disabled due to Unassignment of Token. Please reassign a Token to club to reactivate.")}> DISABLED </div>}
                    </div>}
            </div>
        </div>
    )
}
