import React, { useEffect, useState } from 'react'
import { DELETE_PLACE_BET, postValuebet } from '../../../../../API-Handle/services/auth_curd'
import { successNotification } from '../../../../../Components/CustComp/PPHAlert';
import { DefaultConfirm } from '../../../../../Components/SweetAlerts/DefaultConfirm';
import moment from 'moment';

function DeleteBet({ data, getBets }) {
  const onDelete = async () => {
    let confirmation = await DefaultConfirm(
      "Delete Bet",
      "Are you sure do you want to delete this Bet?",
      "warning"
    );
    if (confirmation) {
      let url = DELETE_PLACE_BET + data.id;
      const response = await postValuebet(url);
      if (response?.message) {
        getBets();
        successNotification(response.message)
      }
    }
  }
  const allowDelete = moment.utc(data?.event_time) > moment.utc();

  return (
    <div
      onClick={allowDelete ? onDelete : undefined}
      role="button"
      className={`ms-2 ps-1 ${!allowDelete ? "pe-none opacity-50 cursor-not-allowed" : ""}`}
    >
      <i className="fa fa-trash font-size-18px" aria-hidden="true"></i>
    </div>
  )
}

export default DeleteBet