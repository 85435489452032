import React, { useState, useContext, useRef, useEffect } from "react";
import { Button } from "reactstrap";
import SendIcon from "@mui/icons-material/Send";
import { useDispatch, useSelector } from "react-redux";
import ValuebetContext from "../../../../context/Valuebet/ValuebetContext";
import { TextField } from "@mui/material";
import { clubchatAction } from "../../../../redux/features/clubchatSlice";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatImageUploader from "./ChatImageUploader";
import { BASEURL } from "../../../../API-Handle/config";

function Index({ width }) {
  const [message, setMessage] = useState("");
  const [height, setHeight] = useState(null);
  const [isOpen, setisOpen] = useState(false);
 
  const { selectedChannelID, replyMessage } = useSelector(
    (state) => state.clubChat
  );
  const { chatConnection } = useContext(ValuebetContext);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageViewer, setimageViewer] = useState(null);

  const renderReplyMessage=(message)=>{
    if(message.media_url)
    {
      const url=BASEURL+ message.media_url
      return <img src={url} width={50} height={50} alt="Chat Icon"/>
    }
    else{
      return message.message;
    }
  }

  const onChange = (e) => {
    setMessage(e.target.value);
  };

  const onClose = () =>{
    setisOpen(!isOpen)
  }

  useEffect(
    () => {
      if (!inputRef?.current) {
        return;
      }

      const resizeObserver = new ResizeObserver(() => {
        if (inputRef?.current?.offsetHeight !== height) {
          setHeight(inputRef?.current?.offsetHeight);
        }
      });

      resizeObserver.observe(inputRef?.current);

      return function cleanup() {
        resizeObserver?.disconnect();
      };
    },
    // eslint-disable-next-line
    [inputRef.current, message]
  );

  useEffect(() => {
    dispatch(clubchatAction.setChatInputHeight(height));
    // eslint-disable-next-line
  }, [height]);

  const onMessageSend = () => {
    inputRef.current.focus();
    dispatch(clubchatAction.setReplyMessage({ data: null }));
    let msgData;
    if (message.trim()) {
      msgData = {
        command: "message_added",
        channel_id: selectedChannelID,
        message: message,
      };
      if (replyMessage) {
        msgData["reply_to"] = replyMessage.id;
      }
      chatConnection.current.sendMessage(msgData);
      // clubchatAction.setChatInputHeight(null);

      setMessage(""); // Clear the input field
    }
  };
  // Send Message on Press Enter
  const handleOnKeyDown = (event) => {
    event.stopPropagation();
    if (event.key === 'Enter' && (event.shiftKey || window.innerWidth < 768)) {
      console.log("inside enter and shift")
      event.preventDefault(); 
      setMessage((prev) => prev + '\n'); 
    }
    else if (event.key === "Enter") {
      event.preventDefault();
      onMessageSend();
    }
  };

  useEffect(() => {
    if (inputRef.current && replyMessage) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line
  }, [replyMessage]);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setimageViewer(reader.result);
      };
      reader.readAsDataURL(file);
      setisOpen(true)
    }
  };

  return (
    <>
      {replyMessage && (
      <div className="reply-message-container">
      <div className="px-2 py-1 d-flex justify-content-between align-items-center reply-message-items">
        <div>
          <h6 className="reply-sender-name-footer">
            {replyMessage?.sender_name}
          </h6>
          {!replyMessage.media_url  && <div className="reply-message-text">
            {renderReplyMessage(replyMessage)}
          </div>}
          {replyMessage.media_url && <div className="reply-message-text">
            {replyMessage.media_type}
          </div> }
        </div>
        <div>
          <div className="d-flex align-items-center">
          {replyMessage.media_url && <div>{renderReplyMessage(replyMessage)}</div>}
          <CloseIcon
            sx={{ cursor: "pointer",marginLeft:"10px" }}
            onClick={() =>
              dispatch(clubchatAction.setReplyMessage({ data: null }))
            }
          />
        </div>
        </div>
      </div>
    </div>
    
      )}
      <div className="message-input p-2 px-3" style={{ width }}>
      <input
        type="file"
        ref={fileInputRef}
        className="d-none"
        onChange={handleFileChange}
      />
      <Button className='font-size-10px me-3' 
      type="file" color='danger' 
      onClick={
        // () => {setisOpen(true)}
         handleButtonClick
        } 
      ><AttachFileIcon /></Button>
        <TextField
          className="form-control-sm me-3"
          placeholder="Your message..."
          onChange={onChange}
          value={message}
          inputRef={inputRef}
          id="chat-input"
          onKeyDown={(e) => handleOnKeyDown(e)}
          multiline
          minRows={1}
          maxRows={5}
          variant="outlined"
          sx={{
            flex: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "rgba(224, 63, 0, 0.3215686275)",
              },
              "&:hover fieldset": {
                borderColor: "rgba(224, 63, 0, 0.3215686275)",
              },
              "&.Mui-focused fieldset": {
                borderColor: "rgba(224, 63, 0, 0.3215686275)",
              },
            },
            "& .MuiInputBase-root": {
              overflow: "auto",
            },
          }}
        />
        <Button
          className="font-size-10px"
          disabled={message ? false : true}
          size="sm"
          color="danger"
          onClick={onMessageSend}
        >
          <SendIcon />
        </Button>
        {isOpen &&<ChatImageUploader onClose={onClose}  selectedFile={selectedFile} imageViewer={imageViewer}/>}
      </div>
    </>
  );
}

export default Index;
