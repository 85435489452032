import React, { useEffect } from 'react'
import Leaderboard from './Leaderboard';
import PrimaryClubInfo from './PrimaryClubInfo';
import PrimaryAction from './PrimaryAction';
import LabelInfo from './LabelInfo';
import { valuebetAction } from '../../../../../redux/features/valuebetSlice';
import {
    Card,
    Button,
  } from "reactstrap";
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

export default function Index({item}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(valuebetAction.setPrimaryClubItem({data:item}))
    },[item])
    
    return (
        <Card className="club-card-container">
            <div className='club-cap'>
                <span className='mt-2 ms-2 ps-1 me-1 d-flex justify-content-between'>
                    <PrimaryAction item={item}/>
                </span>
            </div>
            <div className='px-2 pb-3'>
                <div className='mt-1'>
                    <PrimaryClubInfo item={item} />
                </div>
                <div className="mt-3">
                    <LabelInfo item={item} />
                </div>
                <div className='mt-2'>
                    <Leaderboard club={item} />
                </div>
                <div className='px-2 mt-3'>
                    <Button className='playnowbtn' onClick={() => navigate("/sportbook/sports", {state: { club: item } })} >Play Now <span className='font-size-10px'>(Free Play | Win Coins)</span></Button>
                </div>
            </div>
        </Card>
    )
}
