import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component-footer';
import { PLAYER_BET_HISTORY_REPORT } from '../../../../../../../API-Handle/services/auth_curd';
import { getValuebet } from '../../../../../../../API-Handle/services/auth_curd';
import Spinner from '../../../../../../../Components/CustLoader/Spinner';

const columns = [
    {
        name: '',
        selector: row => row.detail,
    },
    {
        name: 'Net W/L',
        selector: row => row.net_wl,
    },
    {
        name: 'Total Risked',
        selector: row => row.total_risking,
    },
    {
        name: 'No. Wager',
        selector: row => row.number_of_wagers,
    },
    {
        name: 'Ave. Bet',
        selector: row => row.average_bet,
    },
    {
        name: '2022',
        selector: row => row[2022],
    },
    {
        name: '2023',
        selector: row => row[2023],
    },
    {
        name: '2024',
        selector: row => row[2024],
    },
];

export default function YearlyMemberHistory({ club, item }) {
    const [report, setReport] = useState(null)
    useEffect(() => {
        getDailyReports();
        // eslint-disable-next-line
    }, [])

    const getDailyReports = async () => {
        setReport(null)
        let request_url = PLAYER_BET_HISTORY_REPORT + club.id + `?user_id=${item.id}&report_type=Yearly`;
        let reports = await getValuebet(request_url);
        if (reports) setReport(reports);
    }

    return (
        <>
            {report ?
                <DataTable
                    columns={columns}
                    data={report}
                    theme="dark"
                    dense={true}
                    header={false}
                    highlightOnHover={true}
                /> :
                <Spinner />}
        </>
    )
}
