import React, { useEffect, useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { Modal, ModalBody, Button } from "reactstrap";
import { fetchChannelMember } from "../../../redux/services/chatServices";
import CustomForm from "../../CustComp/CustomForm";
import { IconButton, List, ListItem, ListItemText, TextField } from "@mui/material";
import { clubchatAction } from "../../../redux/features/clubchatSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import { DefaultConfirm } from "../../SweetAlerts/DefaultConfirm";
import {
  ADD_REMOVE_CLUB_MEMBER,
  DELETE_CLUB_CHANNEL,
  UPDATE_CHANNEL_NAME,
  postValuebet,
} from "../../../API-Handle/services/auth_curd";
import { successNotification } from "../../CustComp/PPHAlert";
import { useDispatch, useSelector } from "react-redux";
import FindMembers from "../comman/FindMembers";

const PBCHSetting = () => {
    const { selectedChannelID, clubGroupMemer, clubGroup,activeChannel } =
    useSelector((state) => state.clubChat);
  const [selecetedUser, setSelectedUsers] =useState([]);
  const [errorState, setErrorState] =useState("");
  const [name, setName] = useState(activeChannel?.name);
  const [isChannelSettingModalOpen, setIsChannelSettingModalOpen] =
    useState(false);
  const [isLoadingAddMember, setIsLoadingAddMember] = useState(false);
  const [isLoadingRemoveMember, setIsLoadingRemoveMember] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditLoading, setisEditLoading] = useState(false);
  const [isDeleteChannelLoading, setIsDeleteChannelLoading] = useState(false);
  const dispatch=useDispatch();

const handleChangeGroupName = (event) => {
    setName(event.target.value);
  };

  const toggle = () => {
    setIsChannelSettingModalOpen(!isChannelSettingModalOpen);
  };

  const handleUpdateChannel = async () => {
    setisEditLoading(true);
    let URL = `${UPDATE_CHANNEL_NAME}${selectedChannelID}`;
    let payload = {
      name: name,
    };
    let res = await postValuebet(URL, payload);
    setIsEditing(false);
    setisEditLoading(false);
    if (res) {
      const data = JSON.parse(JSON.stringify(activeChannel));
      data.name = name;
      dispatch(clubchatAction.setActiveChannel({ data: data }));
      successNotification(`Channel Name Update Successfully!`);
    }
  };

  useEffect(() => {
    setName(activeChannel?.name);
  }, [activeChannel]);

  const handleClick = () => {
    if (isEditing) {
      handleUpdateChannel();
    } else {
      setIsEditing(!isEditing);
    }
  };

  const addDeleteMember = async (members, actionType) => {
    if (actionType === "Add Member") {
      setIsLoadingAddMember(true);
    } else {
      setIsLoadingRemoveMember(true);
    }
    let URL = `${ADD_REMOVE_CLUB_MEMBER}${selectedChannelID}`;

    let payload = {
      members: members,
      flag: actionType === "Add Member" ? "Add" : "Remove",
    };
    let res = await postValuebet(URL, payload);
    if (actionType === "Add Member") {
      setIsLoadingAddMember(false);
    } else {
      setIsLoadingRemoveMember(false);
    }
    if (res) {
      dispatch(fetchChannelMember(selectedChannelID));
      successNotification(`${actionType} Successfully!`);
    }
  };

  const handleDeleteMember = async (event, member) => {
    event.preventDefault();
    const usersData = [];
    usersData.push(member?.id);
    let confirmation = await DefaultConfirm(
      "Delete Member",
      "Are you sure do you want to delete this Member?",
      "warning"
    );
    if (confirmation) {
      addDeleteMember(usersData, "Remove Member");
    }
  };

  const handleDeleteChannel = async () => {
    let confirmation = await DefaultConfirm(
      "Delete Channel",
      "Are you sure do you want to delete this Channel?",
      "warning"
    );
    if (confirmation) {
      setIsDeleteChannelLoading(true);
      let URL = `${DELETE_CLUB_CHANNEL}${selectedChannelID}`;
      let res = await postValuebet(URL);
      setIsDeleteChannelLoading(false);
      if (res) {
        setIsChannelSettingModalOpen(false);
        const filteredGroup = clubGroup.filter(
          (group) => group.id !== selectedChannelID
        );
        dispatch(clubchatAction.setClubGroup({ data: filteredGroup }));
        dispatch(clubchatAction.setActiveChannel({ data: null }));
        successNotification(res.message);
      }
    }
  };

  const onSubmit = () => {
    setErrorState(null);
    if (selecetedUser.length === 0) {
      setErrorState("Please Select at least 1 Member");
    } else {
      let usersData = [];
      selecetedUser.forEach((item) => {
        usersData.push(item.value);
      });
      addDeleteMember(usersData, "Add Member");
    }
  };

  return (
    <div>
      <SettingsIcon
        fontSize="medium"
        role="button"
        onClick={() => {
          toggle();
          dispatch(fetchChannelMember(selectedChannelID));
        }}
      />
      <Modal
        isOpen={isChannelSettingModalOpen}
        toggle={toggle}
        className="modal-dialog-centered modal-md"
      >
        <div className="modal-header bg-vb-dark">
          {isEditing ? (
            <TextField
              value={name}
              onChange={handleChangeGroupName}
              size="small"
              variant="outlined"
              autoFocus
            />
          ) : (
            <h5 className="modal-title my-0">{activeChannel?.name}</h5>
          )}
          <IconButton
            className="text-white ms-2"
            onClick={handleClick}
            disabled={isEditLoading}
          >
            {isEditing ? <CheckIcon /> : <EditIcon />}
          </IconButton>
          <IconButton
            className="text-white"
            onClick={handleDeleteChannel}
            disabled={isDeleteChannelLoading}
          >
            <DeleteIcon sx={{ color: "#e03f00" }} />
          </IconButton>

          <button
            onClick={toggle}
            type="button"
            className="text-white btn-close"
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <ModalBody>
          <CustomForm onSubmit={onSubmit}>
            <div className="form-group">
              <FindMembers onChange={setSelectedUsers} selecetedItems={selecetedUser} isMulti={true} exincludes={clubGroupMemer} />
              <div className="text-danger font-size-13px mt-1">
                {errorState}
              </div>
            </div>
            <div className="text-end mt-2 ">
              <Button
                type="submit"
                disabled={isLoadingAddMember}
                className="rename-button"
                color="success"
              >
                Add Member
              </Button>
            </div>
            <h6 className="mt-3 text-light">Participants</h6>
            <hr className="mt-0 mb-0" />
            <div className="member-list-container">
              <List>
                {clubGroupMemer?.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                      padding: "2px 15px",
                    }}
                  >
                    <ListItemText primary={item.username} />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      disabled={isLoadingRemoveMember}
                      onClick={(e) => handleDeleteMember(e, item)}
                    >
                      <DeleteIcon className="text-light" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </CustomForm>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PBCHSetting;
